<template>
  <div class="horus-right-column-family-list">
    <h3><img src="/static/images/family.png" />families</h3>
    <div v-if="familiesNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div v-else class="list-wrapper">
      <div v-for="family in families" :key="'familylist' + family.family_id" class="family-block">
        <div class="family-display-wrapper">
          <img src="/static/images/small_both_allow.png" class="relation-state" v-show="family.relation === 1" />
          <img src="/static/images/small_left_allow.png" class="relation-state" v-show="family.relation === 2" />
          <img src="/static/images/small_right_allow.png" class="relation-state" v-show="family.relation === 3" />
          <img v-bind:src="family.author.portrateSrc" class="family-portrate" @click.stop="jumpToTimeline(family)" />
          <span class="unread-count-badge" v-if="timelineVisible(family) && family.unread_count > 0">{{ family.unread_count }}</span>
          <span class="family-name" @click="showFamilyModal(family)">{{ family.author.name }}</span>
          <img src="/static/images/video_meeting_icon.png" class="start-video-meeting" @click="createMeeting(family.author)" v-if="meetingAvailable && timelineVisible(family)" />
          <img src="/static/images/address_exchange_icon.svg" class="exchange-request-icon" @click="sendFamilyExchangeRequest(family.author)" v-if="addressEntryNotIncludesFamily(family.author) && timelineVisible(family)" />
          <horus-author-modal
            :author="family.author"
            :relationship="family.relation"
            :key="'familyLisAuthorModal' + family.family_id"
            :currentOpenAuthor="currentOpenFamily"
            v-on:closeAuthorModalEvent="closeFamilyModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { AddressEntry } from '@/api'

// import { Author } from '@/models'
import vClickOutside from 'click-outside-vue3'
import HorusAuthorModal from '@/components/common/HorusAuthorModal.vue'

import HorusAddressEntry from '@/mixin/HorusAddressEntry'
import HorusAddressExchange from '@/mixin/HorusAddressExchange'

import * as ADDRESS_ENTRY_TYPE from '@/assets/constants/addressEntryType.js'
import * as RELATION_TYPE from '@/assets/constants/userRelationType.js'

export default {
  mixins: [HorusAddressEntry, HorusAddressExchange],

  name: 'HorusRightColumnFamilyList',

  components: {
    HorusAuthorModal,
  },

  props: {
    families: Array,
    loggedInUser: Object,
    signinMember: Object,
    timelineOwner: Object,
    familiesNowLoading: Boolean,
    ownTimeline: Boolean,
    meetingAvailable: Boolean,
  },
  
  data () {
    return {
      currentOpenFamily: '',
    }
  },

  mounted () {
    this.loadAddressEntries()
  },

  methods: {
    timelineVisible (family) {
      // console.log('timeline visible : ownTimeline ' + this.ownTimeline)
      if (this.ownTimeline && (family.relation === RELATION_TYPE.RELATIONSHIP_FAMILY_BOTH || family.relation === RELATION_TYPE.RELATIONSHIP_FAMILY_WATCHING)) {
        // console.log('timeline visible : true')
        return true
      }
      return false
    },

    jumpToTimeline (family) {
      if (this.timelineVisible(family) || (this.loggedInUser && family.family_id === this.loggedInUser.author_id)) {
        // console.log('in list, fire emit.')
        this.$emit('timelineOwnerChangeEvent', family.author)
      }
    },

    showFamilyModal (family) {
      this.currentOpenFamily = family.family_id
    },

    closeFamilyModal () {
      this.currentOpenFamily = ''
    },

    createMeeting (familyAuthor) {
      // console.log('createmetting. ' + familyAuthor)
      this.$emit('createMeetingEvent', familyAuthor)
    },

    addressEntryNotIncludesFamily (author) {
      return this.addressEntryNotIncludes(author.rawID, ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_FAIMILY)
    },

    sendFamilyExchangeRequest (author) {
      this.$emit('sendExchangeRequestEvent', author.name, author.rawID, ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_FAIMILY)
    },

  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
.horus-right-column-family-list {
  width: 100%;
  margin: 0;
  h3 {
    margin: 10px 0 5px 0;
    color: #666;
    font-size: 1em;
    line-height: 1.1;
    font-weight: 500;
    img {
      margin: 0;
      margin-right: 5px;
      width: 10px;
    }
  }
  .list-wrapper {
    margin: 10px 10px 20px 0;
  }
}
.family-block {
  margin: 8px 15px 8px 0;
  font-size: 1.2em;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .family-display-wrapper {
    display: flex;
    align-items: center;
    .relation-state {
      display: inline-block;
      width: 20px;
    }
    .family-portrate {
      width: 40px;
      margin-left: 5px;
    }
    .unread-count-badge {
      position: absolute;
      top: 0;
      left: 60px;
      display: inline-block;
      min-width: 5px;
      padding: 3px 7px;
      background-color: #b94047;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 10px;
    }
    .family-name {
      cursor: pointer;
      margin-left: 20px;
    }
    .start-video-meeting {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
    .exchange-request-icon {
      margin: 5px 10px;
      width: 30px;
    }
  }
}
</style>
