<template>
  <div class="horus-direct-message-view">
    <h1 class="centering-title">
      <img src="/static/images/direct_message_icon.svg" />ダイレクトメッセージ
    </h1>
    <div class="direct-message-content">
      <div class="address-entry-controll">
        <button class="show-address-entries-btn" @click="showAddressEntries">連絡先リスト表示</button>
      </div>
      <div class="direct-message-menu">
        <div class="display-menu">
          <button class="show-receive-btn" :class="{active: !contentSent}" @click="showReceived">受信リスト</button>
          <button class="show-sent-btn" :class="{active: contentSent}" @click="showSent">送信リスト</button>
        </div>
        <div class="create-menu"><button class="create-btn" @click="newMessage">新規作成</button></div>
      </div>
      <div class="sent-content" v-if="contentSent">
        <h2>送信リスト</h2>
        <table class="sent-list">
          <tr><th>送信日時</th><th>表題</th><th>既読状況</th><th></th></tr>
          <tr class="sent-message"
            v-for="directMessage in sentMessages"
            :key="'sentlist' + directMessage.id"
            @click="showSentDetail(directMessage)">
            <td class="sent-at">{{ sentAtStr(directMessage) }}</td>
            <td class="subject">{{ directMessage.subject }}</td>
            <td class="response-state">{{ responseState(directMessage) }}</td>
            <td class="controll"><button class="show-detail-btn" @click="showSentDetail(directMessage)">詳細</button></td>
          </tr>
        </table>
        <div class="load-more" v-if="oldSentExist"><button class="load-more-btn" @click="loadSentMessages">更に読み込む</button></div>
      </div>
      <div class="receive-content" v-if="!contentSent">
        <h2>受信リスト</h2>
        <table class="receive-list">
          <tr><th>送信日時</th><th>送信者</th><th>受信者</th><th>表題</th><th></th></tr>
          <tr class="receive-message"
            v-for="directMessageReceiver in receiveMessages"
            :key="'receivelist' + directMessageReceiver.id"
            @click="showReceiveDetail(directMessageReceiver)">
            <td class="sent-at">
              <div class="sent-at-column">
                <span class="time-display">{{ sentAtStr(directMessageReceiver.direct_message) }}</span>
                <span class="notify-icon" v-if="!directMessageReceiver.read_at">
                  <img src="/static/images/danger_icon.png" />
                </span>
              </div>
            </td>
            <td class="sender">
              <div class="sender-column">
                <img :src="directMessageReceiver.senderAuthor.portrateSrc" class="author-portrate" v-if="directMessageReceiver.senderAuthor" />
                <span class="name">{{ directMessageReceiver.senderAuthor.name }}</span>
              </div>
            </td>
            <td class="receiver">
              <div class="receiver-column">
                <img :src="directMessageReceiver.receiverAuthor.portrateSrc" class="author-portrate" v-if="directMessageReceiver.receiverAuthor" />
                <span class="name" v-if="directMessageReceiver.receiverAuthor">{{ directMessageReceiver.receiverAuthor.name }}</span>
                <span class="name" v-if="directMessageReceiver.organization">{{ directMessageReceiver.organization.shortName }} (代表)</span>
                <span class="deputized-mark" v-if="!ownMessage(directMessageReceiver)">代</span>
              </div>
            </td>
            <td class="subject">{{ directMessageReceiver.direct_message.subject }}</td>
            <td class="controll"><button class="show-detail-btn" @click="showReceiveDetail(directMessageReceiver)">詳細</button></td>
          </tr>
        </table>
        <div class="load-more" v-if="oldReceiveExist"><button class="load-more-btn" @click="loadReceiveMessages">更に読み込む</button></div>
      </div>
    </div>
    <horus-direct-message-form-modal
      :modalDisplay="directMessageFormDisplay"
      :selectedReceivers="selectedAddressEntries"
      v-on:selectReceiversEvent="selectTargetReceivers"
      v-on:removeReceiverEvent="removeSelectedReceiver"
      v-on:cancelEvent="cancelCreateDirectMessage"
      v-on:submitEvent="createDirectMessage"
      />
    <horus-address-entry-select-modal
      :modalDisplay="addressEntrySelectModalDisplay"
      :selectTargets="selectedAddressEntries"
      v-on:selectFinished="addressEntrySelectFinished"
      />
    <horus-direct-message-detail-modal
      :modalDisplay="directMessageDetailDisplay"
      :directMessage="targetDirectMessage"
      v-on:closeEvent="closeSentDetail"
      />
    <horus-direct-message-receiver-modal
      :modalDisplay="directMessageReceiverDetailDisplay"
      :directMessageReceiver="targetDirectMessageReceiver"
      :loggedInUser="loggedInUser"
      :signinMember="signinMember"
      v-on:responseMessageEvent="responseMessage"
      v-on:closeEvent="closeReceiveDetail"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { 
  DirectMessage, 
  // AddressEntry 
} from '@/api'
import { Author, Organization, } from '@/models'

import HorusDirectMessageFormModal from '@/components/directMessage/HorusDirectMessageFormModal.vue'
import HorusAddressEntrySelectModal from '@/components/addressEntry/HorusAddressEntrySelectModal.vue'
import HorusDirectMessageDetailModal from '@/components/directMessage/HorusDirectMessageDetailModal.vue'
import HorusDirectMessageReceiverModal from '@/components/directMessage/HorusDirectMessageReceiverModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as ADDRESS_ENTRY_TYPE from '@/assets/constants/addressEntryType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusDirectMessageView',

  components: {
    HorusAddressEntrySelectModal,
    HorusDirectMessageFormModal,
    HorusDirectMessageDetailModal,
    HorusDirectMessageReceiverModal,
    HorusConfirmDialog,
  },

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  data() {
    return {
      contentSent: false,
      sentMessages: [],
      allSentCount: 0,
      receiveMessages: [],
      allReceiveCount: 0,
      targetDirectMessage: null,
      directMessageDetailDisplay: false,
      targetDirectMessageReceiver: null,
      directMessageReceiverDetailDisplay: false,
      directMessageFormDisplay: false,
      selectedAddressEntries: [],
      addressEntrySelectModalDisplay: false,
    }
  },

  mounted() {
    this.initData()
  },

  computed: {
    oldSentExist () {
      if (this.allSentCount > this.sentMessages.length) {
        return true
      }
      return false
    },

    oldReceiveExist () {
      if (this.allReceiveCount > this.receiveMessages.length) {
        return true
      }
      return false
    },
  },

  methods: {
    initData () {
      this.reloadSentMessages()
      this.reloadReceiveMessages()
    },

    reloadSentMessages () {
      this.sentMessages = []
      this.loadSentMessages()
    },

    reloadReceiveMessages () {
      this.receiveMessages = []
      this.loadReceiveMessages()
    },

    loadSentMessages () {
      DirectMessage.fetchSent({ offset: this.sentMessages.length }).then(async res => {
        console.log(res)
        this.sentMessages = this.sentMessages.concat(res.direct_messages)
        let unloadAuthors = []
        let unloadOrgs = []
        for(let dm of this.sentMessages) {
          for(let dmr of dm.direct_message_receivers) {
            if (dmr.receiver_author_id.length > 0) {
              if (!Author.find(dmr.receiver_author_id)) {
                unloadAuthors.push(dmr.receiver_author_id)
              }
              if (dmr.response_author_id && !Author.find(dmr.response_author_id)) {
                unloadAuthors.push(dmr.response_author_id)
              }
            } else if (dmr.organization_id) {
              if (!Organization.find(dmr.organization_id)) {
                unloadOrgs.push(dmr.organization_id)
              }
            }
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ idList: unloadOrgs})
        }

        for(let dm of this.sentMessages) {
          for(let dmr of dm.direct_message_receivers) {
            dmr.receiverAuthor = Author.find(dmr.receiver_author_id)
            if (dmr.response_author_id && dmr.receiver_author_id.length > 0) {
              dmr.responseAuthor = Author.find(dmr.response_author_id)
            }
            if (dmr.organization_id) {
              dmr.organization = Organization.find(dmr.organization_id)
            }
          }
        }
        this.allSentCount = res.count
      }).catch(error => {
        console.error(error)
      })
    },

    async loadReceiveMessages () {
      DirectMessage.fetchReceive({ offset: this.receiveMessages.length }).then(async res => {
        console.log(res)
        let unloadAuthors = []
        let unloadOrgs  = []
        for(let dmr of res.direct_message_receivers) {
          if (dmr.receiver_author_id && dmr.receiver_author_id.length > 0) {
            if (!Author.find(dmr.receiver_author_id)) {
              unloadAuthors.push(dmr.receiver_author_id)
            }
          }
          if (dmr.organization_id) {
            if (!Organization.find(dmr.organization_id)) {
              unloadOrgs.push(dmr.organization_id)
            }
          }
          if (!Author.find(dmr.direct_message.sender_author_id)) {
            unloadAuthors.push(dmr.direct_message.sender_author_id)
          }
          if (dmr.response_author_id && !Author.find(dmr.response_author_id)) {
            unloadAuthors.push(dmr.response_author_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
        }
        if (unloadOrgs.length > 0) {
          await Organization.fetchByID({ idList: unloadOrgs})
        }
        for(let dmr of res.direct_message_receivers) {
          if (dmr.receiver_author_id && dmr.receiver_author_id.length > 0) {
            dmr.receiverAuthor = Author.find(dmr.receiver_author_id)
          }
          if (dmr.organization_id) {
            dmr.organization = Organization.find(dmr.organization_id)
          }
          dmr.senderAuthor =  Author.find(dmr.direct_message.sender_author_id)
          if (dmr.response_author_id ) {
            dmr.responseAuthor = Author.find(dmr.response_author_id)
          }
          this.receiveMessages.push(dmr)
        }
        this.allReceiveCount = res.count
      }).catch(error => {
        console.error(error)
      })
    },

    sentAtStr (directMessage) {
      if (directMessage.sent_at) {
        const sentAt = new Date(directMessage.sent_at)
        return moment(sentAt).format('MM月DD日 HH:mm')
      }
      return '-----'
    },

    responseState (directMessage) {
      let readCount = 0
      for(let receiver of directMessage.direct_message_receivers) {
        if (receiver.read_at) {
          readCount++
        }
      }
      return readCount + '/' + directMessage.direct_message_receivers.length
    },

    ownMessage (directMessageReceiver) {
      if (this.signinMember && directMessageReceiver.receiver_author_id === this.signinMember.author_id) {
        return true
      } else if (this.loggedInUser && directMessageReceiver.receiver_author_id === this.loggedInUser.author_id) {
        return true
      } else if (
        this.signinMember && 
        this.signinMember.organization_id === directMessageReceiver.organization_id &&
        this.signinMember.member_manager) {
        return true
      }
      return false
    },

    newMessage () {
      this.selectedAddressEntries = []
      this.directMessageFormDisplay = true
    },

    selectTargetReceivers () {
      this.addressEntrySelectModalDisplay = true
    },

    addressEntrySelectFinished (selecteds) {
      this.addressEntrySelectModalDisplay = false
      this.selectedAddressEntries = [...selecteds]
    },

    removeSelectedReceiver (entry) {
      if (entry.target_user_id) {
        let index = this.selectedAddressEntries.findIndex((elm) => elm.target_user_id === entry.target_user_id)
        if (index >= 0) {
          this.selectedAddressEntries.splice(index, 1)
        }
      } else if (entry.target_suom_id) {
        let index = this.selectedAddressEntries.findIndex((elm) => elm.target_suom_id === entry.target_suom_id)
        if (index >= 0) {
          this.selectedAddressEntries.splice(index, 1)
        }
      } else if (entry.target_type == ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
        let index = this.selectedAddressEntries.findIndex((elm) => elm.target_organization_id === entry.target_organization_id)
        if (index >= 0) {
          this.selectedAddressEntries.splice(index, 1)
        }
      }
    },

    cancelCreateDirectMessage () {
      this.directMessageFormDisplay = false
    },

    createDirectMessage (formData) {
      DirectMessage.create({ formData: formData }).then(res => {
        console.log(res)
        this.contentSent = true
        this.directMessageFormDisplay = false
        this.reloadSentMessages()
      }).catch(error => {
        console.error(error)
      })
    },

    showReceived () {
      this.contentSent = false
    },

    showSent () {
      this.contentSent = true
    },

    showSentDetail (directMessage) {
      this.targetDirectMessage = directMessage
      this.directMessageDetailDisplay = true
    },

    closeSentDetail () {
      this.directMessageDetailDisplay = false
      this.targetDirectMessage = null
    },

    showReceiveDetail (directMessageReceiver) {
      this.targetDirectMessageReceiver = directMessageReceiver
      this.directMessageReceiverDetailDisplay = true
    },

    closeReceiveDetail () {
      this.directMessageReceiverDetailDisplay = false
      this.targetDirectMessageReceiver = null
    },

    responseMessage (directMessageReceiver, responseText) {
      this.directMessageReceiverDetailDisplay = false
      DirectMessage.responseMessage({targetID: directMessageReceiver.id, responseText: responseText}).then(res => {
        console.log(res)
        this.targetDirectMessageReceiver = null
        this.showNotifyDialog(
          'ダイレクトメッセージ応答',
          'ダイレクトメッセージを既読にしました。'
        )
        this.initData()
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          'ダイレクトメッセージ応答失敗',
          'ダイレクトメッセージの既読に失敗しました。'
        )
      })
    },

    showAddressEntries () {
      this.$router.push('/address_entry')
    },

  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
@import '@/assets/css/labeledContent.scss';

.horus-direct-message-view {
  height: calc(100% - 70px);
  overflow: auto;
  padding: 10px 50px;
  @include mediaQuery('phone') {
    padding: 10px;
    padding-bottom: 0;
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    img {
      width: 15px;
      margin-right: 3px;
    }
  }
  .address-entry-controll {
    text-align: right;
    .show-address-entries-btn {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 5px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .direct-message-menu {
    display: flex;
    justify-content: space-between;
    button {
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 5px;
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
    .display-menu {
      button {
        margin-right: 10px;
        &.active {
          background-color: #aaa;
        }
      }
    }
  }
  .sent-content {
    h2 {
      margin: 10px 0;
      font-size: 1.1rem;
      font-weight: bold;
      color: #333;
    }
    .sent-list {
      width: 100%;
      border-collapse: collapse;
      tr {
        th {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          text-align: left;
        }
        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          button {
            cursor: pointer;
            padding: 2px 5px;
            border: 1px solid #666;
            border-radius: 3px;
            background-color: #666;
            color: #fff;
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .receive-content {
    h2 {
      margin: 10px 0;
      font-size: 1.1rem;
      font-weight: bold;
      color: #333;
    }
    .receive-list {
      width: 100%;
      border-collapse: collapse;
      tr {
        th {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          text-align: left;
        }
        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          div {
            display: flex;
            align-items: center;
          }
          button {
            cursor: pointer;
            padding: 2px 5px;
            border: 1px solid #666;
            border-radius: 3px;
            background-color: #666;
            color: #fff;
            font-size: 13px;
            font-weight: bold;
          }
          &.sent-at {
            font-weight: bold;
            img {
              margin-left: 5px;
              width: 15px;
            }
          }
          &.sender {
            img {
              margin-right: 5px;
              width: 20px;
            }
          }
          &.receiver {
            img {
              margin-right: 5px;
              width: 20px;
            }
            .deputized-mark {
              display: inline-block;
              margin-left: 5px;
              padding: 3px;
              font-size: 0.7rem;
              font-weight: bold;
              background-color: $danger-color;
              color: #fff;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
</style>