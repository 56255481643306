<template>
  <div class="horus-vital-column" v-if="omronConnectAccountAvailable">
    <h3>
      <a @click="openVitalModal"><img src="/static/images/vital_green.png">vital</a>
      <span><img src="/static/images/gear_icon.png" class="info-content-toggle" @click="openConfigModal" /></span>
    </h3>
    <div v-if="nowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div v-if="!nowLoading && vitalDisplayConfig" class="vital-summary-list">
      <div v-if="vitalDisplayConfig.bloodPressure" class="blood-pressure vital-category-column">
        <div class="vital-value-column">
          <span class="label">血圧</span>
          <span class="value">{{ bloodPressureMax }} / {{ bloodPressureMin }}</span>
          <span class="unit">mmHg</span>
        </div>
        <div class="measure-at">{{ bloodPressureMeasureAt }}</div>
      </div>
      <div v-if="vitalDisplayConfig.bodyComposition" class="body-composition vital-category-column">
        <div v-if="vitalDisplayConfig.bodyCompositionWeight" class="vital-value-column">
          <span class="label">体重</span>
          <span class="value">{{ bodyCompositionWeight }}</span>
          <span class="unit">kg</span>
        </div>
        <div v-if="vitalDisplayConfig.bodyCompositionBodyFatRatio" class="vital-value-column">
          <span class="label">体脂肪率</span>
          <span class="value">{{ bodyCompositionBodyFatRatio }}</span>
          <span class="unit">％</span>
        </div>
        <div v-if="vitalDisplayConfig.bodyCompositionBaseMetabolism" class="vital-value-column">
          <span class="label">基礎代謝</span>
          <span class="value">{{ bodyCompositionBaseMetabolism }}</span>
          <span class="unit">kcal</span>
        </div>
        <div v-if="vitalDisplayConfig.bodyCompositionBodySkeltalMuscleRatio" class="vital-value-column">
          <span class="label">骨格筋率</span>
          <span class="value">{{ bodyCompositionBodySkeltalMuscleRatio }}</span>
          <span class="unit">％</span>
        </div>
        <div v-if="vitalDisplayConfig.bodyCompositionBMI" class="vital-value-column">
          <span class="label">BMI</span>
          <span class="value">{{ bodyCompositionBMI }}</span>
          <span class="unit"></span>
        </div>
        <div v-if="vitalDisplayConfig.bodyCompositionBodyAge" class="vital-value-column">
          <span class="label">体年齢</span>
          <span class="value">{{ bodyCompositionBodyAge }}</span>
          <span class="unit">歳</span>
        </div>
        <div v-if="vitalDisplayConfig.bodyCompositionViceralFatLevel" class="vital-value-column">
          <span class="label">内蔵脂肪レベル</span>
          <span class="value">{{ bodyCompositionViceralFatLevel }}</span>
          <span class="unit"></span>
        </div>
        <div class="measure-at">{{ bodyCompositionMeasureAt }}</div>
      </div>
      <div v-if="vitalDisplayConfig.activeMass" class="active-mass vital-category-column">
        <div v-if="vitalDisplayConfig.activeMassStepCount" class="vital-value-column">
          <span class="label">歩数</span>
          <span class="value">{{ activeMassStepCount }}</span>
          <span class="unit">歩</span>
        </div>
        <div v-if="vitalDisplayConfig.activeMassFastWalkStepCount" class="vital-value-column">
          <span class="label">早歩き歩数</span>
          <span class="value">{{ activeMassFastWalkStepCount }}</span>
          <span class="unit">歩</span>
        </div>
        <div v-if="vitalDisplayConfig.activeMassStairwayStepCount" class="vital-value-column">
          <span class="label">上り階段歩数</span>
          <span class="value">{{ activeMassStairwayStepCount }}</span>
          <span class="unit">歩</span>
        </div>
        <div v-if="vitalDisplayConfig.activeMassUsedCaloriesInActive" class="vital-value-column">
          <span class="label">活動消費カロリー</span>
          <span class="value">{{ activeMassUsedCaloriesInActive }}</span>
          <span class="unit">kcal</span>
        </div>
        <div v-if="vitalDisplayConfig.activeMassTotalUsedCalories" class="vital-value-column">
          <span class="label">総消費カロリー</span>
          <span class="value">{{ activeMassTotalUsedCalories }}</span>
          <span class="unit">kcal</span>
        </div>
        <div v-if="vitalDisplayConfig.activeMassMovingDistance" class="vital-value-column">
          <span class="label">移動距離</span>
          <span class="value">{{ activeMassMovingDistance }}</span>
          <span class="unit">km</span>
        </div>
        <div v-if="vitalDisplayConfig.activeMassBurningBodyFat" class="vital-value-column">
          <span class="label">脂肪燃焼量</span>
          <span class="value">{{ activeMassBurningBodyFat }}</span>
          <span class="unit">g</span>
        </div>
        <div class="measure-at">{{ activeMassMeasureAt }}</div>
      </div>
      <div v-if="vitalDisplayConfig.bodyTemperature" class="body-temperature vital-category-column">
        <div class="vital-value-column">
          <span class="label">体温</span>
          <span class="value">{{ bodyTemperature }}</span>
          <span class="unit">℃</span>
        </div>
        <div class="measure-at">{{ bodyTemperatureMeasureAt }}</div>
      </div>
      <div v-if="vitalDisplayConfig.oxygenSaturation" class="oxygen-saturation vital-category-column">
        <div class="vital-value-column">
          <span class="label">酸素飽和度</span>
          <span class="value">{{ oxygenSaturation }}</span>
          <span class="unit">％</span>
        </div>
        <div class="measure-at">{{ oxygenSaturationMeasureAt }}</div>
      </div>
      <div v-if="vitalDisplayConfig.pulseRate" class="pulse-rate vital-category-column">
        <div class="vital-value-column">
          <span class="label">脈拍</span>
          <span class="value">{{ pulseRate }}</span>
          <span class="unit">bpm</span>
        </div>
        <div class="measure-at">{{ pulseRateMeasureAt }}</div>
      </div>
    </div>
    <div class="vital-option-controll">
      <button type="button" class="force-reload-btn" @click="forceReload">バイタルデータ強制更新</button>
    </div>
  </div>
</template>

<script>
import {
  VitalEntry,
  VitalBloodPressure,
  VitalBodyComposition,
  VitalActiveMass,
  VitalBodyTemperature,
  VitalOxygenSaturation,
  VitalPulseRate
} from '@/models'

import {
  OmronConnectAccount
} from '@/api'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusVitalColumn',

  props: {
    timelineOwner: Object,
    vitalDisplayConfig: Object,
    vitalShouldUpdate: Boolean
  },

  data () {
    return {
      omronConnectAccountAvailable: false,
      vitalUpdateAt: null,
      // vitalDisplayConfig: null,
      nowLoading: true,
      recentBloodPressure: null,
      recentBodyComposition: null,
      recentAcitveMass: null,
      recentBodyTemperature: null,
      recentOxygenSaturation: null,
      recentPulseRate: null
    }
  },

  watch: {
    timelineOwner: { handler: 'prepareUpdateVitalData', immediate: true },
    vitalDisplayConfig: { handler: 'prepareUpdateVitalData', deep: true, immediate: true },
    vitalShouldUpdate: { handler: 'reloadVitalData', immediate: true }
  },

  computed: {
    bloodPressureMax () {
      if (this.recentBloodPressure && (this.recentBloodPressure.max_value || this.recentBloodPressure.max_value === 0)) {
        return this.recentBloodPressure.max_value
      }
      return '---'
    },

    bloodPressureMin () {
      if (this.recentBloodPressure && (this.recentBloodPressure.min_value || this.recentBloodPressure.min_value === 0)) {
        return this.recentBloodPressure.min_value
      }
      return '---'
    },

    bodyCompositionWeight () {
      if (this.recentBodyComposition && (this.recentBodyComposition.weight || this.recentBodyComposition.weight === 0)) {
        return this.recentBodyComposition.weight
      }
      return '---'
    },

    bodyCompositionBodyFatRatio () {
      if (this.recentBodyComposition && (this.recentBodyComposition.body_fat_ratio || this.recentBodyComposition.body_fat_ratio === 0)) {
        return this.recentBodyComposition.body_fat_ratio
      }
      return '---'
    },

    bodyCompositionBaseMetabolism () {
      if (this.recentBodyComposition && (this.recentBodyComposition.base_metabolism || this.recentBodyComposition.base_metabolism === 0)) {
        return this.recentBodyComposition.base_metabolism
      }
      return '---'
    },

    bodyCompositionBodySkeltalMuscleRatio () {
      if (this.recentBodyComposition && (this.recentBodyComposition.body_skeltal_muscle_ratio || this.recentBodyComposition.body_skeltal_muscle_ratio === 0)) {
        return this.recentBodyComposition.body_skeltal_muscle_ratio
      }
      return '---'
    },

    bodyCompositionBMI () {
      if (this.recentBodyComposition && (this.recentBodyComposition.bmi || this.recentBodyComposition.bmi === 0)) {
        return this.recentBodyComposition.bmi
      }
      return '---'
    },

    bodyCompositionBodyAge () {
      if (this.recentBodyComposition && (this.recentBodyComposition.body_age || this.recentBodyComposition.body_age === 0)) {
        return this.recentBodyComposition.body_age
      }
      return '---'
    },

    bodyCompositionViceralFatLevel () {
      if (this.recentBodyComposition && (this.recentBodyComposition.viceral_fat_level || this.recentBodyComposition.viceral_fat_level === 0)) {
        return this.recentBodyComposition.viceral_fat_level
      }
      return '---'
    },

    activeMassStepCount () {
      if (this.recentAcitveMass && (this.recentAcitveMass.step_count || this.recentAcitveMass.step_count === 0)) {
        return this.recentAcitveMass.step_count
      }
      return '---'
    },

    activeMassFastWalkStepCount () {
      if (this.recentAcitveMass && (this.recentAcitveMass.fast_walk_step_count || this.recentAcitveMass.fast_walk_step_count === 0)) {
        return this.recentAcitveMass.fast_walk_step_count
      }
      return '---'
    },

    activeMassStairwayStepCount () {
      if (this.recentAcitveMass && (this.recentAcitveMass.stairway_step_count || this.recentAcitveMass.stairway_step_count === 0)) {
        return this.recentAcitveMass.stairway_step_count
      }
      return '---'
    },

    activeMassUsedCaloriesInActive () {
      if (this.recentAcitveMass && (this.recentAcitveMass.used_calories_in_active || this.recentAcitveMass.used_calories_in_active === 0)) {
        return this.recentAcitveMass.used_calories_in_active
      }
      return '---'
    },

    activeMassTotalUsedCalories () {
      if (this.recentAcitveMass && (this.recentAcitveMass.total_used_calories || this.recentAcitveMass.total_used_calories === 0)) {
        return this.recentAcitveMass.total_used_calories
      }
      return '---'
    },

    activeMassMovingDistance () {
      if (this.recentAcitveMass && (this.recentAcitveMass.moving_distance || this.recentAcitveMass.moving_distance === 0)) {
        return this.recentAcitveMass.moving_distance
      }
      return '---'
    },

    activeMassBurningBodyFat () {
      if (this.recentAcitveMass && (this.recentAcitveMass.burning_body_fat || this.recentAcitveMass.burning_body_fat === 0)) {
        return this.recentAcitveMass.burning_body_fat
      }
      return '---'
    },

    bodyTemperature () {
      if (this.recentBodyTemperature && (this.recentBodyTemperature.value || this.recentBodyTemperature.value === 0)) {
        return this.recentBodyTemperature.value.toFixed(1)
      }
      return '---'
    },

    oxygenSaturation () {
      if (this.recentOxygenSaturation && (this.recentOxygenSaturation.value || this.recentOxygenSaturation.value === 0)) {
        return this.recentOxygenSaturation.value
      }
      return '---'
    },

    pulseRate () {
      if (this.recentPulseRate && (this.recentPulseRate.value || this.recentPulseRate.value === 0)) {
        return this.recentPulseRate.value
      }
      return '---'
    },

    bloodPressureMeasureAt () {
      if (this.recentBloodPressure) {
        return this.recentBloodPressure.measureAtStr
      }
      return '-----'
    },

    bodyCompositionMeasureAt () {
      if (this.recentBodyComposition) {
        return this.recentBodyComposition.measureAtStr
      }
      return '-----'
    },

    activeMassMeasureAt () {
      if (this.recentAcitveMass) {
        return this.recentAcitveMass.measureAtStr
      }
      return '-----'
    },

    bodyTemperatureMeasureAt () {
      if (this.recentBodyTemperature) {
        return this.recentBodyTemperature.measureAtStr
      }
      return '-----'
    },

    oxygenSaturationMeasureAt () {
      if (this.recentOxygenSaturation) {
        return this.recentOxygenSaturation.measureAtStr
      }
      return '-----'
    },

    pulseRateMeasureAt () {
      if (this.recentPulseRate) {
        return this.recentPulseRate.measureAtStr
      }
      return '-----'
    }
  },

  methods: {
    checkOmronConnectAccountAvailable () {
      // console.log('check omron connect account available....')
      // console.log(process.env.VUE_APP_OMRON_CONNECT_AVAILABLE)
      if (this.timelineOwner && process.env.VUE_APP_OMRON_CONNECT_AVAILABLE == 'true') {
        OmronConnectAccount.fetch({ targetUserID: this.timelineOwner.id }).then(res => {
          console.log(res)
          if (res.omronConnectAccount) {
            this.omronConnectAccountAvailable = true
          } else {
            this.omronConnectAccountAvailable = false
          }
        })
      }
    },

    async prepareUpdateVitalData () {
      var executeUpdateFlg = false
      if (!this.omronConnectAccountAvailable) {
        await this.checkOmronConnectAccountAvailable()
      }
      console.log('prepareUpdateVitalData, omronConnectAccountAvailable: ' + this.omronConnectAccountAvailable)
      if (this.vitalUpdateAt) {
        console.log('prepareUpdateVitalData, vitalUpdateAt: ' + this.vitalUpdateAt.toString())
      } else {
        console.log('prepareUpdateVitalData, vitalUpdateAt: undefined')
      }
      if (this.omronConnectAccountAvailable) {
        if (this.vitalUpdateAt) {
          // インターバルチェック(6sec)
          var now = new Date()
          if ((now.getTime() - this.vitalUpdateAt.getTime()) >= 60000) {
            executeUpdateFlg = true
          }
        } else {
          executeUpdateFlg = true
        }
        if (executeUpdateFlg) {
          return this.updateVitalData()
        }
      }
    },

    async updateVitalData () {
      console.log('update vital data call.....')
      console.log('vitalShouldUpdate: ' + this.vitalShouldUpdate)
      console.log('vitalUpdateAt: ' + this.vitalUpdateAt)
      // await this.checkOmronConnectAccountAvailable()
      if (this.omronConnectAccountAvailable) {
        this.nowLoading = true
        if (this.timelineOwner && this.vitalDisplayConfig) {
          let uRes = await OmronConnectAccount.updateVitals({ targetUserID: this.timelineOwner.id })
          console.log(uRes)
          let vitalRes = await VitalEntry.api().fetchNewest({ targetUserID: this.timelineOwner.id, limit: 10 })
          console.log('fetch success...')
          console.log(vitalRes)
          await this.loadVitalSummary()
          this.vitalUpdateAt = new Date()
          this.nowLoading = false
        } else {
          this.nowLoading = false
        }
      }
    },

    async loadVitalSummary () {
      console.log('load vital summary...')
      if (this.vitalDisplayConfig) {
        if (this.vitalDisplayConfig.bloodPressure) {
          if (this.vitalDisplayConfig.bloodPressureUnReliable) {
            this.recentBloodPressure = await VitalBloodPressure.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').first()
          } else {
            this.recentBloodPressure = await VitalBloodPressure.query().where('user_id', this.timelineOwner.id).where('reliability', true).orderBy('measure_at', 'desc').first()
          }
        }
        if (this.vitalDisplayConfig.bodyComposition) {
          this.recentBodyComposition = await VitalBodyComposition.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').first()
        }
        if (this.vitalDisplayConfig.activeMass) {
          this.recentAcitveMass = await VitalActiveMass.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').first()
        }
        if (this.vitalDisplayConfig.bodyTemperature) {
          this.recentBodyTemperature = await VitalBodyTemperature.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').first()
        }
        if (this.vitalDisplayConfig.oxygenSaturation) {
          this.recentOxygenSaturation = await VitalOxygenSaturation.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').first()
        }
        if (this.vitalDisplayConfig.pulseRate) {
          this.recentPulseRate = await VitalPulseRate.query().where('user_id', this.timelineOwner.id).orderBy('measure_at', 'desc').first()
        }
      }
    },

    async reloadVitalData () {
      console.log('reload vital data call,,,,')
      if (this.vitalShouldUpdate) {
        this.prepareUpdateVitalData()
        // this.updateVitalData()
        // await this.updateVitalData()
        this.$emit('vitalUpdatedEvent')
      }
    },

    openVitalModal () {
      console.log('show vital entry modal....')
      this.$emit('showVitalEntryListEvent')
    },

    openConfigModal () {
      this.$emit('showVitalDisplayConfigEvent')
    },

    forceReload () {
      this.$emit('vitalForceReloadEvent', () => { this.executeForceReload() })
    },

    executeForceReload () {
      OmronConnectAccount.forceReloadVitals({ targetUserID: this.timelineOwner.id }).then(res => {
        console.log(res)
        this.updateVitalData()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-vital-column {
  h3 {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 5px 0;
    color: #666;
    font-size: 1em;
    text-align: left;
    a {
      color: #666;
      text-decoration: none;
    }
    img {
      margin-right: 5px;
      width: 10px;
    }
    .info-content-toggle {
      width: 15px;
    }
  }
  .vital-category-column {
    border-bottom: 1px solid #999;
    .vital-value-column {
      display: flex;
      justify-content: center;
      padding: 5px 0;
      border-bottom: 1px solid #ddd;
      .label {
        margin-right: 10px;
        align-self: flex-start;
        font-weight: bold;
        color: #999;
      }
      .value {
        font-size: 24px;
        font-weight: bold;
        color: #999;
        align-self: center;
      }
      .unit {
        margin-left: 5px;
        align-self: flex-end;
        color: #999;
      }
    }
    .measure-at {
      font-size: 12px;
      font-weight: lighter;
      text-align: right;
    }
  }
  .vital-option-controll {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
    .force-reload-btn {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #eee;
      color: #666;
    }
  }
}
</style>
