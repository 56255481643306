<template>
  <div class="navbar">
    <div class="main-bar" :class="signinType">
      <div class="left-navigation">
        <div class="header-logo" @click="jumpToHome">&nbsp;</div>
        <div class="push-notify">
          <horus-task-notifier
            :signinUser="signinUser"
            :signinMember="signinMember"
            :signinReporter="signinReporter"
            :author="author"
            :serverTaskShouldUpdate="serverTaskShouldUpdate"
            :taskDisplayConfiguration="taskDisplayConfiguration"
            v-on:updateCheckedEvent="taskUpdateChecked"
            v-on:showUserTaskDetailEvent="showUserTaskDetail"
            />
        </div>
      </div>
      <div class="signin-user" v-if="author">
        <div class="signin-user-info">
          <div class="signin-user-name">
            <span class="name">{{ author.name }}</span>
            <span class="addtion" v-if="author.nameAdditional.length > 0">{{ author.nameAdditional }}</span>
          </div>
          <a v-click-outside="closeNavbarDropdown" @click="openNavbarDropdown">
            <img :src="signinUserPortrateSrc">
            <horus-main-menu
              :showMenu="showNavbarDropdown"
              :signinUser="signinUser"
              :signinMember="signinMember"
              :signinReporter="signinReporter"
              v-on:menuPageJumpEvent="pageJumpNavi"
              v-on:switchLoginMemberEvent="switchSigninMember"
              v-on:switchLoginReporterEvent="switchSigninReporter"
              v-on:showQuickQrEvent="showQuickQR"
              />
          </a>
        </div>
        <div class="direct-message" @click="showDirectMessage">
          <img src="/static/images/direct_message_icon.svg" />
        </div>
        <div class="activity-logs">
          <a v-click-outside="closeActivityLogDropDown" @click="openActivityLogDropDown">
            <img src="/static/images/activity_log_icon.png" />
            <span class="batch" v-show="activityLogUnreadCount > 0">{{ activityLogUnreadCount }}</span>
            <transition name="activity-log-dropdown"
              @enter="accordionEnter"
              @after-enter="accordionAfterEnter"
              @leave="accordionLeave"
              @after-leave="accordionAfterLeave">
              <div v-show="showActivityLogDropdown" class="activity-logs-dropdown">
                <div v-for="activityLog in activityLogs" :key="activityLog.id" class="activity-log-wrap">
                  <horus-activity-log-in-list
                    :activityLog="activityLog"
                    :key="'activity-log' + activityLog.id"
                    v-on:moveToLinkEvent="moveToLink"
                    />
                </div>
              </div>
            </transition>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import {
  ActivityLog,
  // Author,
} from '@/models'
// import {
//   AddressEntry,
//   DirectMessage,
// } from '@/api'

import ActivityLogAPI from '@/api/ActivityLog'

import HorusActivityLogInList from '@/components/navigation/HorusActivityLogInList.vue'
import HorusMainMenu from '@/components/navigation/HorusMainMenu.vue'
import HorusTaskNotifier from '@/components/navigation/HorusTaskNotifier.vue'

import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

// activity_log読み込みのタイミング
export default {
  mixins: [HorusAccordionTransition],

  name: 'HorusNavbar',

  components: {
    HorusActivityLogInList,
    HorusMainMenu,
    HorusTaskNotifier,
  },

  props: {
    signinUser: Object,
    signinMember: Object,
    signinReporter: Object,
    author: Object,
    serverTaskShouldUpdate: Boolean,
    taskDisplayConfiguration: Object,
  },

  data () {
    return {
      showNavbarDropdown: false,
      showActivityLogDropdown: false,
      activityLogUnreadCount: 0,
      activityLogs: [],
    }
  },

  watch: {
    '$route': 'checkUpdate',

    signinUser: {
      handler: function () {
        this.updateActivityLogUnreadCount()
        this.loadActivityLogs()
      },
      immediate: true
    },

    signinMember: {
      handler: function () {
        this.updateActivityLogUnreadCount()
        this.loadActivityLogs()
      },
      immediate: true
    },

    signinReporter: {
      handler: function () {
        this.updateActivityLogUnreadCount()
        this.loadActivityLogs()
      }
    },

    author: {
      handler: function () {
        this.updateActivityLogUnreadCount()
        this.loadActivityLogs()
      },
      immediate: true
    }
  },

  created () {
    this.$native.appSendWebSetNative = status => {
      this.$native.status = status
      this.qrAvailable = status
    }
  },

  mounted () {
    window.addEventListener('focus', this.checkUpdate)
    
  },

  beforeUnmount () {
    window.removeEventListener('focus', this.checkUpdate)
  },

  computed: {
    signinUserPortrateSrc () {
      if (this.author) {
        return this.author.portrateSrc
      }
      return ''
    },

    signinType () {
      if (this.signinMember) {
        return 'member'
      } else if (this.signinReporter) {
        return 'reporter'
      } else {
        return ''
      }
    },

    managerSignin () {
      if (this.signinMember) {
        if (this.signinMember.member_manager || this.signinMember.relation_manager || this.signinMember.deputize_manager) {
          return true
        }
      }
      return false
    },

    reporterManagerSignin () {
      if (this.signinReporter) {
        if (this.signinReporter.manager) {
          return true
        }
      }
      return false
    },

    // betaEnable () {
    //   return process.env.VUE_APP_BETA_ENABLE
    // }
  },

  methods: {
    checkUpdate () {
      console.log('checkUpdate in NaviBar')
      this.updateActivityLogUnreadCount()
      this.loadActivityLogs()
    },

    updateActivityLogUnreadCount () {
      ActivityLogAPI.unreadCount().then(res => {
        this.activityLogUnreadCount = res.count
        if (this.$native.status) {
          var authorID = ''
          if (this.author) {
            authorID = this.author.id
          } else if (this.signinUser) {
            authorID = 'usr' + this.signinUser.id
          } else if (this.signinMember) {
            authorID = 'mem' + this.signinMember.id
          }
          this.$native.event('badgeCountUpdateEvent', { badgeCount: res.count, authorID: authorID })
        }
      })
    },

    loadActivityLogs () {
      if (this.author) {
        ActivityLog.api().fetchIDList({fromDate: '123456'}).then(() => {
          this.activityLogs = ActivityLog.query().where('target_author', this.author.id).orderBy('created_at', 'desc').limit(50).get()
        })
      }
    },

    openNavbarDropdown () {
      this.showActivityLogDropdown = false
      this.showNavbarDropdown = true
    },

    closeNavbarDropdown () {
      this.showNavbarDropdown = false
    },

    closeAllDropDown () {
      this.showNavbarDropdown = false
      this.showActivityLogDropdown = false
    },

    openActivityLogDropDown () {
      this.activityLogs = ActivityLog.query().where('target_author', this.author.id).orderBy('created_at', 'desc').limit(50).get()
      ActivityLog.api().updateReadFlag().then(() => {
        this.updateActivityLogUnreadCount()
      })
      this.showNavbarDropdown = false
      this.showActivityLogDropdown = true
    },

    closeActivityLogDropDown () {
      this.showActivityLogDropdown = false
    },

    pageJumpNavi (route) {
      // console.log('pageJumpNavi call, in NaviBar')
      // console.log(route)
      this.closeAllDropDown()
      this.$router.push(route)//.then(res => {
        // console.log('pageJumpNavi response in NaviBar')
        // console.log(res)
      // })
    },

    jumpToHome () {
      console.log('path::' + this.$route.path)
      this.closeAllDropDown()
      if (this.signinMember) {
        this.$router.push({ path: '/member_portal' })
      } else if (this.signinReporter) {
        this.$router.push({ path: '/reporter_portal' })
      } else {
        var route = '/maintimeline/' + this.signinUser.id
        if (this.$route.path === route) {
          route = '/maintimeline/'
        }
        this.$router.push({ path: route, force: true })
      }
    },

    switchSigninMember (memberID) {
      this.closeAllDropDown()
      this.$emit('switchLoginMemberEvent', memberID)
    },

    switchSigninReporter (memberID) {
      this.closeAllDropDown()
      this.$emit('switchLoginReporterEvent', memberID)
    },

    moveToLink (activityLog) {
      this.closeAllDropDown()
      ActivityLog.api().toClicked({ targetID: activityLog.id }).then(() => {
        this.showActivityLogDropdown = false
        activityLog.clicked = true
        if (activityLog.link_url) {
          // TODO: 旧システム上のURLを翻訳する必要がある
          if (activityLog.link_url.match(/^http.+$/)) {
            window.open(activityLog.link_url, '_blank')
          } else {
            this.$router.push(activityLog.link_url)
          }
        }
      })
    },

    showQuickQR () {
      this.closeAllDropDown()
      this.$emit('showQuickQrEvent')
    },

    taskUpdateChecked (updateExist) {
      this.$emit('taskUpdateCheckedEvent', updateExist)
    },

    showUserTaskDetail (task) {
      this.$emit('showUserTaskDetailEvent', task)
    },

    showDirectMessage () {
      this.$router.push('/direct_message')
    },

  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss">
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/_basicInputStyle.scss';

.navbar {
  .main-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    height: 34px;
    padding: 8px;
    background-color: $nb-normal-color;
    color: #fff;
    @include mediaQuery('phone') {
      background-image: none;
    }
    .left-navigation {
      display: flex;
      margin: 0;
      padding: 0;
      height: 36px;
      @include mediaQuery('phone') {
        // width: 82px;
        justify-content: space-between;
      }
      .header-logo {
        width: 275px;
        height: 36px;
        background-image: url("/static/images/nb_header_logo_full_normal.png");
        background-repeat: no-repeat;
        @include mediaQuery('phone') {
          width: 36px;
          height: 36px;
          background-image: url("/static/images/narrative_icon_w.png");
          background-repeat: no-repeat;
        }
      }
      .push-notify {
        // z-index: 1001;
        @include mediaQuery('phone') {
          margin-left: 10px;
        }
      }
    }
    &.member {
      background-color: $nb-member-color;
      @include mediaQuery('phone') {
        background-image: none;
      }
      .left-navigation {
        .header-logo {
          background-image: url("/static/images/nb_header_logo_full_member.png");
        }
      }
    }
    &.reporter {
      background-color: $nb-reporter-color;
      @include mediaQuery('phone') {
        background-image: none;
      }
      .left-navigation {
        .header-logo {
          background-image: url("/static/images/nb_header_logo_full_reporter.png");
        }
      }
    }
  }
}
.signin-user {
  display: flex;
  .signin-user-info {
    display: flex;
    .signin-user-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 5px;
      font-weight: bold;
      text-align: right;
    }
    a {
      display: block;
    }
    img {
      width: 30px;
      height: 30px;
    }
    span.addtion {
      font-size: 10px;
    }
  }
}
.direct-message {
  margin-left: 5px;
  padding: 5px;
  position: relative;
  img {
    margin: 0;
    margin-top: 5px;
    width: 30px;
  }
  .batch {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 5px;
    height: 15px;
    width: 20px;
    max-width: 30px;
    margin: 0;
    padding: 3px 0;
    border-radius: 10px;
    background-color: #f00;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }
}
.activity-logs {
  margin: 0 5px;
  margin-left: 5px;
  position: relative;
  img {
    margin: 0;
    margin-top: 10px;
    height: 20px;
  }
  .batch {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 5px;
    height: 15px;
    width: 20px;
    max-width: 30px;
    margin: 0;
    padding: 3px 0;
    border-radius: 10px;
    background-color: #f00;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }
}
.activity-logs-dropdown {
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  width: 400px;
  max-height: 80vh;
  height: 80dvh;
  overflow: scroll;
  background-color: #fff;
  border: 1px solid #aaa;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  @include mediaQuery('phone') {
    right: 0;
    width: calc(100vw - 20px);
  }
  .activity-log-wrap {
    padding: 0;
    border-top: 1px solid #aaa;
    color: #666;
    text-align: left;
  }
}

.menu-dropdown-enter-active, .menu-dropdown-leave-active {
  transition: height 300ms ease;
}
.activity-log-dropdown-enter-active, .activity-log-dropdown-leave-active {
  transition: height 300ms ease;
}
</style>
