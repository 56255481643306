<template>
  <div class="horus-system-mail-form-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />一斉同報メール送信作成</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'表題'"
          :necessary="true"
          :validationState="validateSubject.state"
          :validationMessage="validateSubject.message"
          :attributeNotify="''">
          <input type="text" v-model="subject" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'本文'"
          :necessary="true"
          :validationState="validateContent.state"
          :validationMessage="validateContent.message"
          :attributeNotify="''">
          <textarea v-model="content" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'送信先'"
          :necessary="true"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <select v-model="targetType">
            <option :value="'all'">全ユーザー</option>
            <option :value="'organization'">全施設</option>
          </select>
        </horus-attribute-in-form>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">送信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSystemMailFormModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm
  },

  props: {
    modalDisplay: Boolean
  },

  emits: [
    'cancelSubmitEvent',
    'submitEvent',
  ],

  data () {
    return {
      subject: '',
      content: '',
      targetType: 'all',
    }
  },

  watch: {
    modalDisplay: { handler: 'initDatas', immediate: true }
  },

  computed: {
    validateSubject () {
      if (this.subject.length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '表題は入力必須です。' }
      }
    },

    validateContent () {
      if (this.content.length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '本文は入力必須です。' }
      }
    },

    allValid () {
      if (this.validateSubject.valid && this.validateContent.valid) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initDatas () {
      if (this.modalDisplay) {
        this.subject = ''
        this.content = ''
        this.targetType = 'all'
      }
    },

    cancelSubmit () {
      this.$emit('cancelSubmitEvent')
    },

    submit () {
      if (this.allValid) {
        var formData = new FormData()
        formData.append('subject', this.subject)
        formData.append('content', this.content)
        formData.append('target_type', this.targetType)
        this.$emit('submitEvent', formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-system-mail-form-modal {
  .modal-body {
    padding: 10px 20px;
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
