<template>
  <div class="horus-related-organization-view">
    <horus-deputize-sub-header
      :timelineOwner="timelineOwner"
      v-if="deputizing"
      />
    <div class="related-organization-contents" :class="{deputizing: deputizing}">
      <h1 class="centering-title">かかりつけ設定</h1>
      <div class="organization-list-block">
        <h4>かかりつけになっている施設</h4>
        <div class="caption">かかりつけ設定を行うと、設定したかかりつけ施設の方があなたに関する情報の閲覧や書込みができるようになります。</div>
        <div class="organization-list-column" v-if="!organizationListNowLoading">
          <div class="block-title" @click="organizationListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: organizationListOpen}" />
            施設リスト<span class="caption">施設数:{{ relatedOrganizations.length }}施設</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="organization-list" v-show="organizationListOpen" v-if="relatedOrganizations.length > 0">
              <horus-related-organization-column
                v-for="org in relatedOrganizations"
                :key="'relatedOrg' + org.id"
                :organization="org"
                v-on:removeRelationEvent="removeRelatedOrganization"
                />
            </div>
          </transition>
        </div><!-- organization list column end -->
        <div class="now-loading" v-if="organizationListNowLoading">読み込み中...</div>
      </div><!-- organization list block end -->
      <div class="sent-request-list-block">
        <h4>依頼状の送信</h4>
        <div class="caption">施設へかかりつけ施設の登録依頼を送る</div>
        <div class="block-title">
          <button type="button" class="create-request-btn" @click="createNewRequest">依頼状の作成</button>
        </div>
        <div class="sent-list-column" v-if="!sentListNowLoading">
          <div class="block-title" @click="sentListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListOpen}" />
            依頼状の送信リスト<span class="caption">送信した依頼の数:{{ sentRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-request-list" v-show="sentListOpen" v-if="sentRequests.length > 0">
              <horus-sent-request-for-organization-column
                v-for="sentRequest in sentRequests"
                :key="'sentReq' + sentRequest.id"
                :sentRequest="sentRequest"
                v-on:removeRequestEvent="removeSentRequest"
                />
            </div>
          </transition>
        </div><!-- sent request list column end -->
        <div class="now-loading" v-if="sentListNowLoading">読み込み中...</div>
      </div><!-- sent request list block end -->
      <div class="receive-list-block">
        <h4>案内状の受信</h4>
        <div class="receive-list-column" v-if="!receiveListNowLoading">
          <div class="block-title" @click="receiveRequestListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: receiveListOpen}" />
            案内状の受信リスト<span class="caption notice">受け取った案内の数:{{ receiveRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="receive-request-list" v-show="receiveListOpen">
              <div class="no-receive-notify" v-if="receiveRequests.length <= 0">受信したかかりつけ登録案内はありません。</div>
              <div v-if="receiveRequests.length > 0">
                <horus-receive-request-for-user-column
                  v-for="receiveRequest in receiveRequests"
                  :key="'receiveReq' + receiveRequest.id"
                  :receiveRequest="receiveRequest"
                  v-on:confirmRequestEvent="confirmRequest"
                  />
              </div>
            </div>
          </transition>
        </div><!-- receive list column end -->
        <div class="now-loading" v-if="receiveListNowLoading">読み込み中...</div>
      </div>
      <horus-footer />
    </div>
    <horus-create-request-for-organization-modal
      :showCreateRequestModal="showCreateRequestModal"
      :relatedOrganizations="relatedOrganizations"
      :sentRequests="sentRequests"
      :receiveRequests="receiveRequests"
      :timelineOwner="timelineOwner"
      v-on:cancelEvent="cancelCreateRequest"
      v-on:submitSendRequestEvent="submitSendRequest"
      />
    <horus-confirm-request-for-user-modal
      :showConfirmRequestModal="showConfirmRequestModal"
      :request="confirmTargetRequest"
      v-on:admitRequestEvent="admitReceiveRequest"
      v-on:rejectRequestEvent="rejectReceivedRequest"
      v-on:cencelConfirmEvent="cancelConfirmRequest"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Organization,
  Author,
  TimelineOwner
} from '@/models'

import {
  Permission,
  ServiceRelationRequestForOrganization,
  ServiceRelationRequestForUser,
  RelatedOrganization
} from '@/api'

import HorusDeputizeSubHeader from '@/components/navigation/HorusDeputizeSubHeader.vue'

import HorusRelatedOrganizationColumn from '@/components/relatedOrganization/HorusRelatedOrganizationColumn.vue'
import HorusSentRequestForOrganizationColumn from '@/components/relatedOrganization/HorusSentRequestForOrganizationColumn.vue'
import HorusReceiveRequestForUserColumn from '@/components/relatedOrganization/HorusReceiveRequestForUserColumn.vue'
import HorusCreateRequestForOrganizationModal from '@/components/relatedOrganization/HorusCreateRequestForOrganizationModal.vue'
import HorusConfirmRequestForUserModal from '@/components/relatedOrganization/HorusConfirmRequestForUserModal.vue'

import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition],

  name: 'HorusRelatedOrganizationView',

  components: {
    HorusDeputizeSubHeader,
    HorusRelatedOrganizationColumn,
    HorusSentRequestForOrganizationColumn,
    HorusReceiveRequestForUserColumn,
    HorusConfirmDialog,
    HorusCreateRequestForOrganizationModal,
    HorusConfirmRequestForUserModal,
    HorusFooter
  },

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  data () {
    return {
      timelineOwner: null,
      deputizing: false,
      relatedOrganizations: [],
      sentRequests: [],
      receiveRequests: [],
      organizationListNowLoading: true,
      sentListNowLoading: true,
      receiveListNowLoading: true,
      organizationListOpen: false,
      sentListOpen: false,
      receiveListOpen: false,
      confirmTargetRequest: null,
      showConfirmRequestModal: false,
      showCreateRequestModal: false
    }
  },

  watch: {
    '$route': 'initTimelineOwner',
    loggedInUser: { handler: 'initTimelineOwner', immediate: true },
    timelineOwner: { handler: 'initObjects', immediate: true }
  },

  methods: {
    async initTimelineOwner () {
      if (this.$route.params.id) {
        await TimelineOwner.api().fetch({ timelineOwnerID: this.$route.params.id[0] })
        var owner = TimelineOwner.find(this.$route.params.id[0])
        if (!Author.find(owner.author_id)) {
          await Author.api().findByID({ timelineOwnerID: owner.id, idList: [owner.author_id] })
        }
        this.timelineOwner = owner
        if (this.$route.query.deputize) {
          await Permission.configure({ targetUserID: this.timelineOwner.id }).then(() => {
            this.deputizing = true
          })
        }
      } else if (this.loggedInUser) {
        this.timelineOwner = this.loggedInUser
      }
    },

    async initObjects () {
      if (this.timelineOwner) {
        this.loadRealtedOrganizations()
        this.loadSentRequests()
        this.loadReceiveRequests()
      }
    },

    async loadRealtedOrganizations () {
      this.organizationListNowLoading = true
      Organization.api().fetchRelatedIDList({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        var unloadID = []
        for(var orgID of res.organizations) {
          if (!Organization.find(orgID)) {
            if (unloadID.indexOf(orgID) < 0) unloadID.push(orgID)
          }
        }
        if (unloadID.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadID })
          this.relatedOrganizations = await Organization.findIn(res.organizations)
          this.organizationListNowLoading = false
        } else {
          this.relatedOrganizations = await Organization.findIn(res.organizations)
          this.organizationListNowLoading = false
        }
      })
    },

    async loadSentRequests () {
      this.sentListNowLoading = true
      await ServiceRelationRequestForOrganization.fetch({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        var unloadOrgs = []
        for(var rel of res.service_relation_request_for_organizations) {
          if (!Organization.find(rel.organization_id)) {
            if (unloadOrgs.indexOf(rel.organization_id) < 0) unloadOrgs.push(rel.organization_id)
          }
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadOrgs })
        }
        for(var srel of res.service_relation_request_for_organizations) {
          srel.organization = Organization.find(srel.organization_id)
        }
        this.sentRequests = res.service_relation_request_for_organizations
      })
      this.sentListNowLoading = false
    },

    async loadReceiveRequests () {
      this.receiveListNowLoading = true
      await ServiceRelationRequestForUser.fetchReceiveRequests({ timelineOwnerID: this.timelineOwner.id }).then(async res => {
        var unloadOrgs = []
        for(var srel of res.service_relation_request_for_users) {
          if (!Organization.find(srel.organization_id)) {
            if (unloadOrgs.indexOf(srel.organization_id) < 0) unloadOrgs.push(srel.organization_id)
          }
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadOrgs })
        }
        for(var rel of res.service_relation_request_for_users) {
          rel.organization = Organization.find(rel.organization_id)
        }
        this.receiveRequests = res.service_relation_request_for_users
      })
      this.receiveListNowLoading = false
    },

    organizationListToggle () {
      this.organizationListOpen = !this.organizationListOpen
    },

    sentListToggle () {
      this.sentListOpen = !this.sentListOpen
    },

    receiveRequestListToggle () {
      this.receiveListOpen = !this.receiveListOpen
    },

    async removeRelatedOrganization (organization) {
      this.showConfirmDialog(
        'かかりつけ施設のかかりつけを停止します',
        '現在あなたのかかりつけになっている施設「' + organization.name + '」のかかりつけを停止します。\nかかりつけを停止した場合、今後この施設の職員はあなたのタイムラインを閲覧できず、書き込みも出来なくなります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          await RelatedOrganization.destroy({ timelineOwnerID: this.timelineOwner.id, organizationID: organization.id }).then(() => {
            this.loadRealtedOrganizations()
            this.showNotifyDialog(
              'かかりつけ施設の停止完了',
              organization.shortName + 'のかかりつけを停止しました。\n再度相手に依頼を送り、承認されるまで、' + organization.shortName + 'の職員はあなたのタイムラインを閲覧できません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadRealtedOrganizations()
            this.showNotifyDialog(
              'かかりつけの停止失敗',
              organization.shortName + 'のかかりつけ停止に失敗しました。'
            )
          })
        }
      )
    },

    createNewRequest () {
      this.showCreateRequestModal = true
    },

    cancelCreateRequest () {
      this.showCreateRequestModal = false
    },

    async submitSendRequest (targetOrg, message, callBack) {
      var formData = new FormData()
      formData.append('target_user_id', this.timelineOwner.id)
      formData.append('organization_id', targetOrg.id)
      formData.append('service_relation_request_for_organization[message_content]', message)
      await ServiceRelationRequestForOrganization.create({ formData: formData }).then(() => {
        this.loadSentRequests()
        this.showNotifyDialog(
          '依頼状送信完了',
          targetOrg.shortName + '宛てに、かかりつけ登録依頼を送信しました。\n相手の承認をお待ちください。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '依頼状送信失敗',
          targetOrg.shortName + '宛てに、かかりつけ登録依頼を送信しようとしましたが、失敗しました。'
        )
      }).finally(() => {
        this.showCreateRequestModal = false
        callBack()
      })
    },

    removeSentRequest (request) {
      this.showConfirmDialog(
        'かかりつけ依頼を削除します',
        request.organization.shortName + '宛のかかりつけ依頼を削除します。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          await ServiceRelationRequestForOrganization.destroy({ timelineOwnerID: this.timelineOwner.id, targetID: request.id }).then(() => {
            this.loadSentRequests()
            this.showNotifyDialog(
              'かかりつけ依頼の削除完了',
              request.organization.shortName + '宛てのかかりつけ依頼を削除しました。'
            )
          }).catch(error => {
            console.error(error)
            this.loadSentRequests()
            this.showNotifyDialog(
              'かかりつけ依頼の削除失敗',
              request.organization.shortName + '宛てのかかりつけ依頼の削除に失敗しました。'
            )
          })
        }
      )
    },

    confirmRequest (request) {
      this.confirmTargetRequest = request
      this.showConfirmRequestModal = true
    },

    async admitReceiveRequest (request) {
      this.showConfirmDialog(
        'かかりつけ案内の承認',
        request.organization.shortName + 'からのかかりつけ案内を承認します。\n実行した結果、' + request.organization.shortName + 'はあなたのかかりつけ施設となり、施設の職員があなたのタイムラインの閲覧と書き込みが行えるようになります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeAdmitReceiveRequest(request)
        }
      )
    },

    async executeAdmitReceiveRequest (request) {
      await ServiceRelationRequestForUser.admitOnSignin({ timelineOwnerID: this.timelineOwner.id, targetID: request.id }).then(() => {
        this.loadRealtedOrganizations()
        this.loadReceiveRequests()
        this.showNotifyDialog(
          'かかりつけ案内の承認完了',
          request.organization.shortName + 'からのかかりつけ登録を承認しました。\n' + request.organization.shortName + 'はあなたのかかりつけになりました。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          'かかりつけ案内の承認失敗',
          request.organization.shortName + 'からのかかりつけ登録の承認に失敗しました。'
        )
      })
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    },

    async rejectReceivedRequest (request) {
      this.showConfirmDialog(
        'かかりつけ案内の拒否',
        request.organization.shortName + 'からのかかりつけ案内を拒否します。\n実行した結果、この案内は削除され、施設の管理者に拒否の結果が通知されます。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRejectReceiveRequest(request)
        }
      )
    },

    async executeRejectReceiveRequest (request) {
      await ServiceRelationRequestForUser.rejectOnSignin({ timelineOwnerID: this.timelineOwner.id, targetID: request.id }).then(() => {
        this.loadRealtedOrganizations()
        this.loadReceiveRequests()
        this.showNotifyDialog(
          'かかりつけ案内の拒否完了',
          request.organization.shortName + 'からのかかりつけ登録を拒否しました。\n' + request.organization.shortName + 'からのかかりつけ案内は破棄されました。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          'かかりつけ案内の拒否失敗',
          request.organization.shortName + 'からのかかりつけ登録の拒否に失敗しました。'
        )
      })
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    },

    cancelConfirmRequest () {
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-related-organization-view {
  height: calc(100% - 50px);
  overflow: auto;
  .related-organization-contents {
    height: calc(100% - 10px);
    padding: 10px 50px 0 50px;
    overflow: auto;
    background-color: #f5f5f5;
    &.deputizing {
      height: calc(100% - 73px);
    }
    @include mediaQuery('phone') {
      padding: 10px;
      padding-bottom: 0;
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h4 {
      margin: 10px 0;
      padding-bottom: 5px;
      color: #6c272d;
      border-bottom: 1px solid #aaa;
      font-size: 18px;
      font-weight: normal;
    }
    .block-title {
      cursor: pointer;
      margin: 10px 0;
      font-weight: bold;
      img {
        width: 13px;
        transition: transform 300ms ease;
        &.open {
          transform: rotate(90deg);
        }
      }
      .caption {
        font-size: 12px;
        font-weight: normal;
        &.notice {
          color: #c43d53;
        }
      }
      .create-request-btn {
        cursor: pointer;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
      }
    }
    .organization-list {
      overflow: hidden;
    }
    .sent-request-list {
      overflow: hidden;
    }
    .receive-request-list {
      overflow: hidden;
    }
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 300ms ease;
}
</style>
