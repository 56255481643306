<template>
  <div class="horus-agent-config-view">
    <horus-deputize-sub-header
      :timelineOwner="timelineOwner"
      v-if="deputizing"
      />
    <div class="agent-contents" :class="{deputizing: deputizing}">
      <h1 class="centering-title">設定代行</h1>
      <div class="notify-caption">
        このページでは、あなたの代理者/設定代行施設に関する設定を行います。<br/>
        代理者/設定代行施設とは、あなたのプロフィール、かかりつけ施設、家族、設定代行をあなたに代わって行う権限を持つ施設/利用者のことです。<br/>
        設定代行の権限を持つ施設/利用者は、あなたのナラティブブックの重要な設定を変更する権限を持つものとなるため、事前に確認の上、依頼状を送信するか、案内状の承諾を行ってください。<br/>
        施設に関してはナラティブブック運営者により、設定代行を実行できる権限を付与されている必要があります。<br/>
        設定代行権限を持たない施設には、設定代行の依頼を行うことはできません。事前に確認しておいてください。<br/>
        利用者に関しては、ナラティブブック運営者による権限付与などは必要ありませんので、誰にでも依頼状を送信できます。<br/>
      </div>
      <div class="organization-list-block">
        <h4>設定代行をしている施設</h4>
        <div class="organization-list-column" v-if="!organizationListNowLoading">
          <div class="block-title" @click="organizationListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: organizationListOpen}" />
            施設リスト<span class="caption">施設数:{{ deputizeOrganizations.length }}施設</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="organization-list" v-show="organizationListOpen" v-if="deputizeOrganizations.length > 0">
              <horus-deputize-organization-column
                v-for="org in deputizeOrganizations"
                :key="'relatedOrg' + org.id"
                :organization="org"
                v-on:removeDeputizeOrganizationEvent="removeDeputizeOrganization"
                />
            </div>
          </transition>
        </div><!-- organization list column end -->
        <div class="now-loading" v-if="organizationListNowLoading">読み込み中...</div>
        <h4 v-if="betaEnable">代理者</h4>
        <div class="agent-list-column" v-if="!agentListNowLoading && betaEnable">
          <div class="block-title" @click="agentListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: agentListOpen}" />
            代理者リスト<span class="caption">代理者数:{{ deputizeAgents.length }}人</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="organization-list" v-show="agentListOpen" v-if="deputizeAgents.length > 0">
              <horus-deputize-agent-column
                v-for="agent in deputizeAgents"
                :key="'relatedAgent' + agent.id"
                :agent="agent"
                :removable="agentRemovable(agent)"
                v-on:removeDeputizeAgentEvent="removeDeputizeAgent"
                />
            </div>
          </transition>
        </div><!-- organization list column end -->
        <div class="now-loading" v-if="agentListNowLoading && betaEnable">読み込み中...</div>
      </div><!-- organization list block end -->
      <div class="sent-request-list-block">
        <h4>依頼申請の送信</h4>
        <div class="caption">施設へ設定代行施設の登録申請を送る</div>
        <div class="block-title">
          <button type="button" class="create-request-btn" @click="createNewRequestForOrganization">施設宛依頼申請の作成</button>
        </div>
        <div class="sent-list-column" v-if="!sentListForOrgNowLoading">
          <div class="block-title" @click="sentListForOrgToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListForOrgOpen}" />
            施設宛依頼申請の送信リスト<span class="caption">送信した申請の数:{{ sentRequestsForOrg.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-request-list" v-show="sentListForOrgOpen" v-if="sentRequestsForOrg.length > 0">
              <horus-sent-deputize-request-for-organization-column
                v-for="sentRequest in sentRequestsForOrg"
                :key="'sentReqOrg' + sentRequest.id"
                :sentRequest="sentRequest"
                v-on:removeRequestEvent="removeSentRequestForOrg"
                />
            </div>
          </transition>
        </div><!-- sent request list column end -->
        <div class="now-loading" v-if="sentListForOrgNowLoading">読み込み中...</div>
        <div class="caption" v-if="betaEnable">他の利用者へ代理依頼申請を送る</div>
        <div class="block-title" v-if="betaEnable">
          <button type="button" class="create-request-btn" @click="createNewRequestForAgent">代理依頼申請の作成</button>
        </div>
        <div class="sent-list-column" v-if="!sentListForAgentNowLoading && betaEnable">
          <div class="block-title" @click="sentListForAgentToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: sentListForAgentOpen}" />
            代理依頼申請の送信リスト<span class="caption">送信した申請の数:{{ sentRequestsForAgent.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="sent-request-list" v-show="sentListForAgentOpen" v-if="sentRequestsForAgent.length > 0">
              <horus-sent-deputize-request-for-agent-column
                v-for="sentRequest in sentRequestsForAgent"
                :key="'sentReq' + sentRequest.id"
                :sentRequest="sentRequest"
                v-on:sendMailRequestEvent="sendMailRequestForAgent"
                v-on:removeRequestEvent="removeSentRequestForAgent"
                />
            </div>
          </transition>
        </div><!-- sent request list column end -->
        <div class="now-loading" v-if="sentListForAgentNowLoading && betaEnable">読み込み中...</div>
      </div><!-- sent request list block end -->
      <div class="receive-list-block">
        <h4>案内状の受信</h4>
        <div class="receive-list-column" v-if="!receiveListNowLoading">
          <div class="block-title" @click="receiveRequestListToggle">
            <img src="/static/images/open_close_triangle.png" :class="{open: receiveListOpen}" />
            案内状の受信リスト<span class="caption notice">受け取った案内の数:{{ receiveRequests.length }}件</span>
          </div>
          <transition
            name="accordion-block"
            @enter="accordionEnter"
            @after-enter="accordionAfterEnter"
            @leave="accordionLeave"
            @after-leave="accordionAfterLeave">
            <div class="receive-request-list" v-show="receiveListOpen">
              <div class="no-receive-notify" v-if="receiveRequests.length <= 0">受信した設定代行登録案内はありません。</div>
              <div v-if="receiveRequests.length > 0">
                <horus-receive-deputizing-request-to-user-column
                  v-for="receiveRequest in receiveRequests"
                  :key="'receiveReq' + receiveRequest.id"
                  :receiveRequest="receiveRequest"
                  v-on:confirmRequestEvent="confirmRequest"
                  />
              </div>
            </div>
          </transition>
        </div><!-- receive list column end -->
      </div>
      <horus-footer />
    </div>
    <horus-create-deputize-request-for-organization-modal
      :showCreateRequestModal="showCreateRequestForOrganizationModal"
      :deputizeOrganizations="deputizeOrganizations"
      :sentRequests="sentRequestsForOrg"
      :receiveRequests="receiveRequests"
      :timelineOwner="timelineOwner"
      v-on:cancelEvent="cancelCreateRequestForOrganization"
      v-on:submitSendRequestEvent="submitSendRequestForOrganization"
      />
    <horus-create-deputize-request-for-agent-modal
      v-if="betaEnable"
      :showCreateRequestModal="showCreateRequestForAgentModal"
      :deputizeAgents="deputizeAgents"
      :sentRequests="sentRequestsForAgent"
      :receiveRequests="receiveRequests"
      :timelineOwner="timelineOwner"
      v-on:cancelEvent="cancelCreateRequestForAgent"
      v-on:submitSendRequestEvent="submitSendRequestForAgent"
      />
    <horus-confirm-deputizing-request-to-user-modal
      :showConfirmRequestModal="showConfirmRequestModal"
      :request="confirmTargetRequest"
      v-on:admitRequestEvent="admitReceiveRequest"
      v-on:rejectRequestEvent="rejectReceivedRequest"
      v-on:cencelConfirmEvent="cancelConfirmRequest"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  Organization,
  Author,
  TimelineOwner
} from '@/models'

import {
  Permission,
  OrganizationDeputizeRelation,
  AgentDeputizeRelation,
  DeputizeRequestForOrganization,
  DeputizeRequestForUser,
  DeputizingRequestToUser
} from '@/api'

import HorusDeputizeSubHeader from '@/components/navigation/HorusDeputizeSubHeader.vue'
import HorusDeputizeOrganizationColumn from '@/components/agentConfig/HorusDeputizeOrganizationColumn.vue'
import HorusDeputizeAgentColumn from '@/components/agentConfig/HorusDeputizeAgentColumn.vue'
import HorusSentDeputizeRequestForOrganizationColumn from '@/components/agentConfig/HorusSentDeputizeRequestForOrganizationColumn.vue'
import HorusSentDeputizeRequestForAgentColumn from '@/components/agentConfig/HorusSentDeputizeRequestForAgentColumn.vue'
import HorusReceiveDeputizingRequestToUserColumn from '@/components/agentConfig/HorusReceiveDeputizingRequestToUserColumn.vue'
import HorusCreateDeputizeRequestForOrganizationModal from '@/components/agentConfig/HorusCreateDeputizeRequestForOrganizationModal.vue'
import HorusCreateDeputizeRequestForAgentModal from '@/components/agentConfig/HorusCreateDeputizeRequestForAgentModal.vue'
import HorusConfirmDeputizingRequestToUserModal from '@/components/agentConfig/HorusConfirmDeputizingRequestToUserModal.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusAccordionTransition from '@//mixin/HorusAccordionTransition.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusAccordionTransition],

  name: 'HorusAgentConfigView',

  components: {
    HorusDeputizeSubHeader,
    HorusDeputizeOrganizationColumn,
    HorusDeputizeAgentColumn,
    HorusSentDeputizeRequestForOrganizationColumn,
    HorusSentDeputizeRequestForAgentColumn,
    HorusReceiveDeputizingRequestToUserColumn,
    HorusCreateDeputizeRequestForOrganizationModal,
    HorusCreateDeputizeRequestForAgentModal,
    HorusConfirmDeputizingRequestToUserModal,
    HorusConfirmDialog,
    HorusFooter
  },

  props: {
    loggedInUser: Object,
    signinMember: Object
  },

  data () {
    return {
      timelineOwner: null,
      deputizing: false,
      organizationListNowLoading: true,
      organizationListOpen: false,
      deputizeOrganizations: [],
      agentListNowLoading: true,
      agentListOpen: false,
      deputizeAgents: [],
      sentListForOrgNowLoading: true,
      sentListForOrgOpen: false,
      sentRequestsForOrg: [],
      sentListForAgentNowLoading: true,
      sentListForAgentOpen: false,
      sentRequestsForAgent: [],
      receiveListNowLoading: true,
      receiveListOpen: false,
      receiveRequests: [],
      confirmTargetRequest: null,
      showCreateRequestForOrganizationModal: false,
      showCreateRequestForAgentModal: false,
      showConfirmRequestModal: false,
      excludeOrganizations: []
    }
  },

  watch: {
    '$route': 'initTimelineOwner',
    loggedInUser: { handler: 'initTimelineOwner', immediate: true },
    timelineOwner: { handler: 'initObjects', immediate: true }
  },

  computed: {
    betaEnable () {
      return process.env.VUE_APP_BETA_ENABLE
    }
  },

  methods: {
    async initTimelineOwner () {
      console.log("process.env.VUE_APP_BETA_ENABLE")
      console.log(process.env.VUE_APP_BETA_ENABLE)
      if (this.$route.params.id) {
        await TimelineOwner.api().fetch({ timelineOwnerID: this.$route.params.id[0] })
        var owner = await TimelineOwner.find(this.$route.params.id[0])
        if (!Author.find(owner.author_id)) {
          await Author.api().fetchByID({ idList: [owner.author_id] })
        }
        this.timelineOwner = owner
        if (this.$route.query.deputize) {
          await Permission.configure({ targetUserID: this.timelineOwner.id }).then(() => {
            this.deputizing = true
          })
        }
      } else if (this.loggedInUser) {
        this.timelineOwner = this.loggedInUser
      }
    },

    initObjects () {
      this.loadDeputizeOrganizations()
      if (this.betaEnable) {
        this.loadDeputizeAgents()
      }
      this.loadSentRequestsForOrg()
      if (this.betaEnable) {
        this.loadSentRequestsForAgent()
      }
      this.loadReceiveRequests()
    },

    async loadDeputizeOrganizations () {
      if (this.timelineOwner) {
        this.organizationListNowLoading = true
        await OrganizationDeputizeRelation.fetchDeputizeOrganizationIDList({ userID: this.timelineOwner.id }).then(async res => {
          var unloadID = []
          for(var orgID of res.deputize_organizations) {
            if (!Organization.find(orgID)) {
              if (unloadID.indexOf(orgID) < 0) unloadID.push(orgID)
            }
          }
          if (unloadID.length > 0) {
            await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadID })
          }
          this.deputizeOrganizations = Organization.findIn(res.deputize_organizations)
        }).catch(err => {
          console.log(err)
        })
        this.organizationListNowLoading = false
      }
    },

    async loadDeputizeAgents () {
      if (this.timelineOwner) {
        this.agentListNowLoading = true
        var res = await AgentDeputizeRelation.fetchDeputizeAgentIDList({ clientUserID: this.timelineOwner.id })
        var agentIDList = []
        var unloadID = []
        for(var agentID of res.deputize_agents) {
          var agentAuhorID = 'usr' + agentID
          agentIDList.push(agentAuhorID)
          if (!Author.find(agentAuhorID)) {
            if (unloadID.indexOf(agentAuhorID) < 0) unloadID.push(agentAuhorID)
          }
        }
        if (unloadID.length > 0) {
          await Author.api().fetchByID({ idList: unloadID })
        }
        this.deputizeAgents = Author.findIn(agentIDList)
        this.agentListNowLoading = false
      }
    },

    async loadSentRequestsForOrg () {
      if (this.timelineOwner) {
        this.sentListForOrgNowLoading = true
        var res = await DeputizeRequestForOrganization.fetchList({ userID: this.timelineOwner.id })
        var unloadOrgs = []
        for(var rel of res.deputize_request_for_organizations) {
          if (!Organization.find(rel.organization_id)) {
            if (unloadOrgs.indexOf(rel.organization_id) < 0) unloadOrgs.push(rel.organization_id)
          }
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadOrgs })
        }
        for(var relation of res.deputize_request_for_organizations) {
          relation.organization = await Organization.find(relation.organization_id)
        }
        this.sentRequestsForOrg = res.deputize_request_for_organizations
        this.sentListForOrgNowLoading = false
      }
    },

    async loadSentRequestsForAgent () {
      if (this.timelineOwner) {
        this.sentListForAgentNowLoading = true
        var res = await DeputizeRequestForUser.fetchList({ userID: this.timelineOwner.id })
        this.sentRequestsForAgent = res.deputize_request_for_users
        this.sentListForAgentNowLoading = false
      }
    },

    async loadReceiveRequests () {
      if (this.timelineOwner) {
        this.receiveListNowLoading = true
        var res = await DeputizeRequestForUser.fetchReceiveRequests({ userID: this.timelineOwner.id })
        console.log(res)
        var unloadOrgs = []
        var unloadAuthors = []
        if (res.deputizing_request_to_users) {
          for(var rel of res.deputizing_request_to_users) {
            if (rel.organization_id) {
              if (!Organization.find(rel.organization_id)) {
                if (unloadOrgs.indexOf(rel.organization_id) < 0) unloadOrgs.push(rel.organization_id)
              }
            } else if (rel.agent_user_id) {
              // load author
              if (!Author.find('usr' + rel.agent_user_id)) {
                if (unloadAuthors.indexOf(rel.agent_user_id) < 0) unloadAuthors.push('usr' + rel.agent_user_id)
              }
            }
          }
        }
        if (unloadOrgs.length > 0) {
          await Organization.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadOrgs })
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ timelineOwnerID: this.timelineOwner.id, idList: unloadAuthors })
        }
        for(var relation of res.deputizing_request_to_users) {
          if (relation.organization_id) {
            relation.organization = await Organization.find(relation.organization_id)
          } else if (rel.agent_user_id) {
            relation.agentUser = await Author.find('usr' + relation.agent_user_id)
          }
        }
        this.receiveRequests = res.deputizing_request_to_users
        this.receiveListNowLoading = false
      }
    },

    organizationListToggle () {
      this.organizationListOpen = !this.organizationListOpen
    },

    agentListToggle () {
      this.agentListOpen = !this.agentListOpen
    },

    sentListForOrgToggle () {
      this.sentListForOrgOpen = !this.sentListForOrgOpen
    },

    sentListForAgentToggle () {
      this.sentListForAgentOpen = !this.sentListForAgentOpen
    },

    receiveRequestListToggle () {
      this.receiveListOpen = !this.receiveListOpen
    },

    agentRemovable (agent) {
      if (this.timelineOwner.selfSignin || (this.loggedInUser && this.loggedInUser.id !== agent.rawID)) {
        return true
      }
      return false
    },

    // NOTE: should use try-catch?
    removeDeputizeOrganization (organization) {
      this.showConfirmDialog(
        '設定代行施設を停止します',
        '現在あなたの設定代行施設になっている施設「' + organization.name + '」の設定代行を停止します。\n設定代行を停止した場合、今後この施設の設定代行者は、あなたのプロフィール、かかりつけ設定、家族設定にアクセス出来なくなります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          return OrganizationDeputizeRelation.destroyOrganization({ userID: this.timelineOwner.id, organizationID: organization.id }).then(() => {
            this.loadDeputizeOrganizations()
            this.showNotifyDialog(
              '設定代行施設の停止完了',
              organization.shortName + 'の設定代行を停止しました。\n再度相手に依頼を送り、承認されるまで、' + organization.shortName + 'の設定代行者はあなたのプロフィール、かかりつけ設定、家族設定にアクセスできません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadDeputizeOrganizations()
            this.showNotifyDialog(
              '設定代行の停止失敗',
              organization.shortName + 'の設定代行停止に失敗しました。'
            )
          })
        }
      )
    },

    removeDeputizeAgent (agent) {
      this.showConfirmDialog(
        '代理者の権限を停止します',
        '現在あなたの代理者になっている利用者「' + agent.name + '」の代理者権限を停止します。\n代理を停止した場合、今後この利用者は、あなたのプロフィール、かかりつけ設定、家族設定にアクセス出来なくなります。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          return AgentDeputizeRelation.destroyAgent({ targetUserID: this.timelineOwner.id, agentUserID: agent.rawID }).then(() => {
            this.loadDeputizeAgents()
            this.showNotifyDialog(
              '代理者の権限停止完了',
              agent.name + 'の代理者権限を停止しました。\n再度相手に依頼を送り、承認されるまで、' + agent.name + 'はあなたのプロフィール、かかりつけ設定、家族設定にアクセスできません。'
            )
          }).catch(error => {
            console.error(error)
            this.loadDeputizeOrganizations()
            this.showNotifyDialog(
              '代理者の停止失敗',
              agent.name + 'の代理者権限停止に失敗しました。'
            )
          })
        }
      )
    },

    createNewRequestForOrganization () {
      this.showCreateRequestForOrganizationModal = true
    },

    cancelCreateRequestForOrganization () {
      this.showCreateRequestForOrganizationModal = false
    },

    async submitSendRequestForOrganization (targetOrg, message) {
      var formData = new FormData()
      formData.append('target_user_id', this.timelineOwner.id)
      formData.append('organization_id', targetOrg.id)
      formData.append('deputize_request_for_organization[message_content]', message)
      await DeputizeRequestForOrganization.create({ formData: formData }).then(() => {
        this.loadSentRequestsForOrg()
        this.showNotifyDialog(
          '設定代行申請送信完了',
          targetOrg.shortName + '宛てに、設定代行依頼申請を送信しました。\n相手の承認をお待ちください。'
        )
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '設定代行申請送信失敗',
          targetOrg.shortName + '宛てに、設定代行登録申請を送信しようとしましたが、失敗しました。'
        )
      })
      this.showCreateRequestForOrganizationModal = false
    },

    removeSentRequestForOrg (request) {
      this.showConfirmDialog(
        '設定代行申請を削除します',
        request.organization.shortName + '宛の設定代行申請を削除します。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          await DeputizeRequestForOrganization.destroy({ userID: this.timelineOwner.id, targetID: request.id }).then(() => {
            this.loadSentRequestsForOrg()
            this.showNotifyDialog(
              '設定代行申請の削除完了',
              request.organization.shortName + '宛ての設定代行申請を削除しました。'
            )
          }).catch(error => {
            console.error(error)
            this.loadSentRequestsForOrg()
            this.showNotifyDialog(
              '設定代行申請の削除失敗',
              request.organization.shortName + '宛ての設定代行申請の削除に失敗しました。'
            )
          })
        }
      )
    },

    createNewRequestForAgent () {
      this.showCreateRequestForAgentModal = true
    },

    cancelCreateRequestForAgent () {
      this.showCreateRequestForAgentModal = false
    },

    async submitSendRequestForAgent (formData, callback) {
      await DeputizeRequestForUser.create({ formData: formData }).then(() => {
        this.loadSentRequestsForAgent()
        this.showNotifyDialog(
          '代理依頼申請送信完了',
          '代理依頼申請を送信しました。\n相手の承認をお待ちください。'
        )
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '代理依頼申請送信失敗',
          '代理依頼申請を送信しようとしましたが、失敗しました。'
        )
      })
      callback()
      this.showCreateRequestForAgentModal = false
    },

    sendMailRequestForAgent (request) {
      DeputizeRequestForUser.sendMail({ userID: this.timelineOwner.id, targetID: request.id }).then(async () => {
        await this.loadSentRequestsForAgent()
        this.showNotifyDialog(
          '代理依頼申請再送信完了',
          '代理依頼申請を再送信しました。\n申請の利用期限が二日後に再設定されました。また、本人確認コードの試行回数がリセットされました。\n相手の承認をお待ちください。'
        )
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog(
          '代理依頼申請再送信失敗',
          '代理依頼申請の再送信に失敗しました。'
        )
      })
    },

    removeSentRequestForAgent (request) {
      this.showConfirmDialog(
        '代理依頼申請を削除します',
        '代理依頼申請を削除します。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        async () => {
          await DeputizeRequestForUser.destroy({ userID: this.timelineOwner.id, targetID: request.id }).then(() => {
          // await this.$store.dispatch('destroyDeputizeRequestForUser', { userID: this.timelineOwner.id, targetID: request.id }).then(res => {
            this.loadSentRequestsForAgent()
            this.showNotifyDialog(
              '代理依頼申請の削除完了',
              '代理依頼申請を削除しました。'
            )
          }).catch(error => {
            console.error(error)
            this.loadSentRequestsForAgent()
            this.showNotifyDialog(
              '代理依頼申請の削除失敗',
              '代理依頼申請の削除に失敗しました。'
            )
          })
        }
      )
    },

    confirmRequest (request) {
      this.confirmTargetRequest = request
      this.showConfirmRequestModal = true
    },

    admitReceiveRequest (request) {
      this.showConfirmDialog(
        '設定代行案内を承認します',
        '設定代行案内を承認します。\n承認すると、送信者があなたの設定代行者になり、送信元に承認した通知が送信されます。この操作は後戻りできません。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeAdmitReceiveRequest(request) }
      )
    },

    async executeAdmitReceiveRequest (request) {
      await DeputizingRequestToUser.admitOnSignin({ userID: this.timelineOwner.id, targetID: request.id }).then(() => {
      // await this.$store.dispatch('admitOnSigninDeputizingRequestToUser', { userID: this.timelineOwner.id, targetID: request.id }).then(res => {
        if (request.organization) {
          this.loadDeputizeOrganizations()
          this.loadReceiveRequests()
          this.showNotifyDialog(
            '設定代行案内の承認完了',
            request.organization.shortName + 'からの設定代行登録を承認しました。\n' + request.organization.shortName + 'はあなたの設定代行施設になりました。'
          )
        } else if (request.agentUser) {
          this.loadDeputizeAgents()
          this.loadReceiveRequests()
          this.showNotifyDialog(
            '設定代行案内の承認完了',
            request.agentUser.name + 'からの代理者案内を承認しました。\n' + request.agentUser.name + 'はあなたの代理者になりました。'
          )
        }
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '設定代行案内の承認失敗',
          '設定代行登録の承認に失敗しました。'
        )
      })
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    },

    rejectReceivedRequest (request) {
      this.showConfirmDialog(
        '設定代行案内を拒否します',
        '設定代行案内を拒否します。\n拒否すると、この案内は削除され、送信元に拒否した通知が送信されます。この操作は後戻りできません。\n実行してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => { this.executeRejectReceivedRequest(request) }
      )
    },

    async executeRejectReceivedRequest (request) {
      await DeputizingRequestToUser.rejectOnSignin({ userID: this.timelineOwner.id, targetID: request.id }).then(() => {
      // await this.$store.dispatch('rejectOnSigninDeputizingRequestToUser', { userID: this.timelineOwner.id, targetID: request.id }).then(res => {
        if (request.organization) {
          this.loadDeputizeOrganizations()
          this.loadReceiveRequests()
          this.showNotifyDialog(
            '設定代行案内の拒否完了',
            request.organization.shortName + 'からの設定代行登録を拒否しました。\n' + request.organization.shortName + 'からの設定代行案内は破棄されました。'
          )
        } else if (request.agentUser) {
          this.loadDeputizeAgents()
          this.loadReceiveRequests()
          this.showNotifyDialog(
            '設定代行案内の拒否完了',
            request.agentUser.name + 'からの代理者案内を拒否しました。\n' + request.agentUser.name + 'からの代理者案内は破棄されました。'
          )
        }
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '設定代行案内の拒否失敗',
          '設定代行登録の拒否に失敗しました。'
        )
      })
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    },

    cancelConfirmRequest () {
      this.showConfirmRequestModal = false
      this.confirmTargetRequest = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/mediaQuery.scss';

.horus-agent-config-view {
  height: calc(100% - 50px);
  overflow: auto;
  .agent-contents {
    height: calc(100% - 10px);
    padding: 10px 50px 0 50px;
    overflow: auto;
    background-color: #f5f5f5;
    @include mediaQuery('phone') {
      padding: 10px;
      padding-bottom: 0;
    }
    &.deputizing {
      height: calc(100% - 73px);
    }
    .centering-title {
      margin: 0 0 10px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
    }
    h4 {
      margin: 10px 0;
      padding-bottom: 5px;
      color: #6c272d;
      border-bottom: 1px solid #aaa;
      font-size: 18px;
      font-weight: normal;
    }
    .notify-caption {
      padding: 10px;
      border: 1px solid #6c272d;
    }
    .block-title {
      cursor: pointer;
      margin: 10px 0;
      font-weight: bold;
      img {
        width: 13px;
        transition: transform 300ms ease;
        &.open {
          transform: rotate(90deg);
        }
      }
      .caption {
        font-size: 12px;
        font-weight: normal;
        &.notice {
          color: #c43d53;
        }
      }
      .create-request-btn {
        cursor: pointer;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #999;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
      }
    }
    .organization-list {
      overflow: hidden;
    }
    .sent-request-list {
      overflow: hidden;
    }
    .receive-request-list {
      overflow: hidden;
    }
  }
}

.accordion-block-enter-active, .accordion-block-leave-active {
  transition: height 300ms ease;
}
</style>
