import axios from './AxiosInstance'

export default {
  fetch: ({ offset }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'sendgrid_log/list',
        params: { offset: offset },
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },
}