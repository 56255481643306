// import { AddressEntry } from '@/api'

import * as ADDRESS_ENTRY_TYPE from '@/assets/constants/addressEntryType.js'

export default {
  methods: {
    addressEntryNotIncludes (targetID, targetType) {
      console.log('targetID:' + targetID + ' targetType:' + targetType)
      console.log(this.loggedInUser)
      console.log(this.signinMember)
      if (targetType === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_FAIMILY || targetType === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_NONE) {
        if (this.loggedInUser && targetID === this.loggedInUser.id) {
          return false
        }
        if (this.addressEntries.some((entry) => entry.target_user_id === targetID)) {
          return false
        }
      } else if (targetType === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_SUOM) {
        if (this.signinMember && targetID === this.signinMember.id) {
          console.log('same suom')
          return false
        }
        if (this.addressEntries.some((entry) => entry.target_suom_id === targetID)) {
          return false
        }
      } else if (targetType === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
        if (this.signinMember && this.signinMember.organization_id === targetID) {
          return false
        }
        if (this.addressEntries.some((entry) => entry.target_organization_id === targetID && entry.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION)) {
          return false
        }
      }
      return true
    },

    // sendExchangeRequest (targetID, targetType, positiveCallback, negativeCallback) {
    //   AddressEntry.exchangeRequest({ targetID: targetID, targetType: targetType }).then(res => {
    //     console.log(res)
    //     positiveCallback()
    //   }).catch(error => { 
    //     console.error(error) 
    //     negativeCallback()
    //   })
    // },
  },
}