<template>
  <div class="horus-direct-message-form-modal">
    <horus-modal-frame :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1><img src="/static/images/direct_message_icon.svg" class="title-icon" />新規ダイレクトメッセージ作成</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'表題'"
          :necessary="true"
          :validationState="subjectValidation.state"
          :validationMessage="subjectValidation.message"
          :attributeNotify="''">
          <input type="text" v-model="subject" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'本文'"
          :necessary="true"
          :validationState="contentValidation.state"
          :validationMessage="contentValidation.message"
          :attributeNotify="''">
          <textarea v-model="content"></textarea>
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'要回答'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="check-input" @click="toggleNeedText">
            <horus-custom-check-box
              :active="needResponseText"
              :enabled="true"
              :type="'color'"
              />
            回答テキストを求める
          </span>
        </horus-attribute-in-form>
        <div class="receiver-select-controll"><button class="receiver-select-btn" @click="selectReceiver">送信先選択</button></div>
        <div class="receivers-column">
          <div class="receiver-column"
            v-for="entry in selectedReceivers"
            :key="'recvent' + entry.id">
            <img :src="entry.author.portrateSrc" v-if="entry.author" />
            <span class="author-name">{{ entry.name }}</span>
            <img src="/static/images/batsu_icon.png" class="close-icon" @click="removeReciver(entry)" />
          </div>
        </div>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendMessage">キャンセル</button>
          <button class="send-request-btn" :class="{ disable: !messageReady }" @click="submitSendMessage">ダイレクトメッセージ送信</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import * as ADDRESS_TARGET_TYPE from '@/assets/constants/addressEntryType';

export default {
  name: 'HorusDirectMessageFormModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox,
  },

  props: {
    modalDisplay: Boolean,
    selectedReceivers: Array,
  },

  emits: [
    'selectReceiversEvent',
    'removeReceiverEvent',
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      subject: '',
      content: '',
      needResponseText: false,
    }
  },

  watch: {
    modalDisplay: { handler: 'initForm', immediate: true },
  },

  computed: {
    subjectValidation () {
      if (this.subject.trim().length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '表題は入力必須です' }
      }
    },

    contentValidation () {
      if (this.content.trim().length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '本文は入力必須です' }
      }
    },

    messageReady () {
      if (this.subjectValidation.valid && this.contentValidation.valid && this.selectedReceivers.length > 0) {
        return true
      }
      return false
    },
  },

  methods: {
    initForm () {
      if (this.modalDisplay) {
        this.subject = ''
        this.content = ''
        this.needResponseText = false
      }
    },

    toggleNeedText () {
      this.needResponseText = !this.needResponseText
    },

    selectReceiver () {
      this.$emit('selectReceiversEvent')
    },

    removeReciver (entry) {
      this.$emit('removeReceiverEvent', entry)
    },

    cancelSendMessage () {
      this.$emit('cancelEvent')
    },

    submitSendMessage () {
      if (this.messageReady) {
        let formData = new FormData()
        formData.append('direct_message[subject]', this.subject)
        formData.append('direct_message[content]', this.content)
        formData.append('direct_message[need_response_text]', this.needResponseText)
        for(let entry of this.selectedReceivers) {
          if (entry.target_user_id) {
            formData.append('direct_message[receiver_users][]', entry.target_user_id)
          } else if (entry.target_suom_id) {
            formData.append('direct_message[receiver_suoms][]', entry.target_suom_id)
          } else if (entry.target_type === ADDRESS_TARGET_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
            formData.append('direct_message[receiver_organizations][]', entry.target_organization_id)
          }
        }
        this.$emit('submitEvent', formData)
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/labeledContent.scss';

.horus-direct-message-form-modal {
  .modal-body {
    padding: 10px 20px;
    textarea {
      width: calc(100% - 60px);
    }
    .receiver-select-controll {
      padding: 5px;
      button {
        cursor: pointer;
        padding: 2px 5px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
      }
    }
    .receivers-column {
      display: flex;
      flex-wrap: wrap;
      .receiver-column {
        margin: 5px 10px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          margin-right: 5px;
          &.close-icon {
            margin-left: 5px;
            width: 15px;
          }
        }
      }
    }
    .submit-controll {
      padding: 10px;
      text-align: right;
      button {
        margin-left: 10px;
        cursor: pointer;
        padding: 2px 5px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.disable {
          background-color: #aaa;
        }
      }
    }
  }
}
</style>