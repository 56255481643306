<template>
  <div class="horus-system-mail-view">
    <h1 class="centering-title">メール一斉送信</h1>
    <button class="new-mail-btn" @click="newSystemMail">新規作成</button>
    <h2>送信済みメール一覧</h2>
    <div class="sent-list">
      <div class="list-column"
        v-for="mailLog in mailLogs"
        :key="'sentMailLog' + mailLog.id"
        >
        <div class="list-line">
          <div class="sent-at">送信日 : [{{ formedDate(mailLog.created_at) }}]</div>
          <div class="subject">表題：{{ mailLog.subject }}</div>
          <div class="count">送信件数 : {{ mailLog.target_count }}</div>
          <div class="type">送信タイプ : {{ mailLog.target_type }}</div>
          <div class="controll"><button type="button" class="show-btn" @click="showDetail(mailLog)">詳細</button></div>
        </div>
      </div>
    </div>
    <div class="contact-controlls"><button class="load-btn" @click="appendMailLog">更に読み込む</button></div>
    <horus-system-mail-form-modal
      :modalDisplay="formModalDisplay"
      v-on:cancelSubmitEvent="closeFormModal"
      v-on:submitEvent="submitSystemMail"
      />
    <horus-system-mail-log-detail-modal
      :modalDisplay="detailModalDisplay"
      :systemMailLog="detailMailLog"
      v-on:closeEvent="closeDetail"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { SystemMail } from '@/api'

import HorusSystemMailFormModal from '@/components/manageSystem/systemMail/HorusSystemMailFormModal.vue'
import HorusSystemMailLogDetailModal from '@/components/manageSystem/systemMail/HorusSystemMailLogDetailModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemMailView',

  components: {
    HorusSystemMailFormModal,
    HorusSystemMailLogDetailModal,
    HorusConfirmDialog,
  },

  data () {
    return {
      mailLogs: [],
      limit: 100,
      currentOffset: 0,
      detailMailLog: null,
      detailModalDisplay: false,
      formModalDisplay: false,
    }
  },

  mounted () {
    this.initDatas()
  },

  methods: {
    initDatas () {
      this.detailContact = null
      this.currentOffset = 0
      this.mailLogs = []
      this.loadMailLog()
    },

    loadMailLog () {
      SystemMail.fetch({ offset: this.currentOffset }).then(res => {
        console.log(res)
        this.mailLogs = this.mailLogs.concat(res.system_mail_logs)
        this.currentOffset += this.mailLogs.length
      }).catch(err => {
        console.error(err)
      })
    },

    appendMailLog () {
      this.loadMailLog()
    },

    reloadMailLog () {
      this.initDatas()
    },

    formedDate (time) {
      var date = new Date(time)
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm:ss')
      }
    },

    showDetail (mailLog) {
      this.detailMailLog = mailLog
      this.detailModalDisplay = true
    },

    closeDetail () {
      console.log('close detail')
      this.detailModalDisplay = false
      this.detailMailLog = null
    },

    newSystemMail () {
      this.formModalDisplay = true
    },

    closeFormModal () {
      this.formModalDisplay = false
    },

    submitSystemMail (formData) {
      SystemMail.create({ formData: formData}).then(res => {
        console.log(res)
        this.formModalDisplay = false
        this.reloadMailLog()
        let target = 'ユーザー'
        if (res.system_mail_log.target_type == 'organization') {
          target = '施設'
        }
        this.showNotifyDialog(
          'メール一斉送信完了',
          res.system_mail_log.target_count + '件の' + target + 'にメールを一斉送信しました。'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          'メール一斉送信失敗',
          'メールの一斉送信に失敗しました。'
        )
      })
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-mail-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .new-mail-btn {
    border: 1px solid #039;
    border-radius: 3px;
    background-color: #039;
    color: #fff;
    font-weight: bold;
  }
  .sent-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: 200px;
            font-weight: bold;
          }
          &.mail {
            width: 250px;
          }
          &.from {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .contact-controlls {
    margin: 10px 0;
    text-align: center;
    .load-btn {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
