import axios from './AxiosInstance'

export default {
  fetchSent: ({ offset }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'direct_message/sent_list',
        params: { offset: offset },
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetchReceive: ({ offset }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'direct_message/receive_list',
        params: { offset: offset },
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetchNotified: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'direct_message/notified_list',
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  create: ({formData}) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'direct_message',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({targetID}) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'direct_message',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  responseMessage: ({targetID, responseText}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'direct_message/response_message',
            withCredentials: true,
            params: { id: targetID, response_text: responseText }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  reserveResponse: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'direct_message/reserve_response',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

}