<template>
  <div class="horus-direct-message-receiver-modal">
    <horus-modal-frame :showModalFlag="modalDisplayState">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/direct_message_icon.svg" />
          {{ titleText }}
        </div>
        <div class="close-btn" v-if="!forceDisplay">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="sent-at direct-message-block">
          <div class="title">送信日時</div>
          <div class="direct-message-content">
            <span class="time-display">{{ formedTime(directMessageReceiver.direct_message.sent_at) }}</span>
            <span class="notify-icon" v-if="!directMessageReceiver.read_at">
              <img src="/static/images/danger_icon.png" />
            </span>
          </div>
        </div>
        <div class="sender direct-message-block">
          <div class="title">送信者</div>
          <div class="direct-message-content">
            <img :src="directMessageReceiver.senderAuthor.portrateSrc" class="author-portrate" v-if="directMessageReceiver.senderAuthor" />
            <span class="name" v-if="directMessageReceiver.senderAuthor">{{ directMessageReceiver.senderAuthor.name }}</span>
          </div>
        </div>
        <div class="receiver direct-message-block">
          <div class="title">受信者</div>
          <div class="direct-message-content">
            <img :src="directMessageReceiver.receiverAuthor.portrateSrc" class="author-portrate" v-if="directMessageReceiver.receiverAuthor" />
            <span class="name" v-if="directMessageReceiver.receiverAuthor">{{ directMessageReceiver.receiverAuthor.name }}</span>
            <span class="name" v-if="directMessageReceiver.organization">{{ directMessageReceiver.organization.shortName }} (代表)</span>
            <span class="deputized-mark" v-if="!ownMessage">代理依頼者</span>
          </div>
        </div>
        <div class="subject direct-message-block">
          <div class="title">表題</div>
          <div class="direct-message-content">
            {{ directMessageReceiver.direct_message.subject }}
          </div>
        </div>
        <div class="text-content direct-message-block">
          <div class="title">本文</div>
          <div class="direct-message-content" v-html="textCommentHtml"></div>
        </div>
        <div class="read-at direct-message-block">
          <div class="title">既読状況</div>
          <div class="direct-message-content" v-if="!directMessageReceiver.read_at">
            未読
          </div>
          <div class="direct-message-content" v-if="directMessageReceiver.read_at">
            <span class="read-at-display">{{ formedTime(directMessageReceiver.read_at) }} 既読</span>
            <img :src="directMessageReceiver.responseAuthor.portrateSrc" class="responder-portrate" v-if="directMessageReceiver.responseAuthor" />
            <span class="name" v-if="directMessageReceiver.responseAuthor">{{ directMessageReceiver.responseAuthor.name }}</span>
          </div>
        </div>
        <div class="response-text direct-message-block" v-if="directMessageReceiver.response_text">
          <div class="title">回答</div>
          <div class="direct-message-content">
            {{ directMessageReceiver.response_text }}
          </div>
        </div>
        <div class="controll">
          <div class="direct-message-controll" v-if="directMessageReceiver.direct_message.functional_flg === FUNCTIONAL_FLG_DIRECT_MESSAGE">
            <button class="reserve-response-btn" @click="reserveResponse" v-if="!ownMessage && !directMessageReceiver.read_at">保留</button>
            <button class="to-read-btn" 
              :class="{disable: responseFormDisplay}"
              @click="responseMessage" 
              v-if="!directMessageReceiver.read_at">既読にする</button>
            <div class="response-form" v-show="responseFormDisplay">
              <label>返信テキスト<input type="text" v-model="responseText" /></label>
              <button class="send-response-btn"
                :class="{disable: responseText.length <= 0}" 
                @click="responseMessageWithText">送信</button>
            </div>
          </div>
          <div class="address-exchange-controll" v-if="directMessageReceiver.direct_message.functional_flg === FUNCTIONAL_FLG_ADDRESS_EXCHANGE">
            <button class="accept-btn" @click="acceptRequest">承認</button>
            <button class="reject-btn" @click="rejectRequest">拒否</button>
          </div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as DIRECT_MESSAGE_FUNCTION from '@/assets/constants/directMessageFunctionalFlags.js'

export default {
  name: 'HorusDirectMessageReceiverModal',

  components: {
    HorusModalFrame,
  },

  props: {
    modalDisplay: Boolean,
    directMessageReceiver: Object,
    forceDisplay: Boolean,
    loggedInUser: Object,
    signinMember: Object,
  },

  data () {
    return {
      responseFormDisplay: false,
      responseText: '',
      FUNCTIONAL_FLG_DIRECT_MESSAGE: DIRECT_MESSAGE_FUNCTION.FUNCTIONAL_FLG_DIRECT_MESSAGE,
      FUNCTIONAL_FLG_ADDRESS_EXCHANGE: DIRECT_MESSAGE_FUNCTION.FUNCTIONAL_FLG_ADDRESS_EXCHANGE,
    }
  },

  emits: [
    'responseMessageEvent',
    'closeEvent',
    'reserveResponseEvent',
    'acceptExchangeEvent',
    'rejectExchangeEvent',
  ],

  watch: {
    directMessageReceiver: { handler: 'initData', deep: true, immediate: true },
  },

  computed: {
    modalDisplayState () {
      if (this.directMessageReceiver) {
        return true
      } else {
        return false
      }
    },

    titleText () {
      if (this.directMessageReceiver.direct_message.functional_flg == DIRECT_MESSAGE_FUNCTION.FUNCTIONAL_FLG_DIRECT_MESSAGE) {
        return '受信ダイレクトメッセージ'
      } else if (this.directMessageReceiver.direct_message.functional_flg == DIRECT_MESSAGE_FUNCTION.FUNCTIONAL_FLG_ADDRESS_EXCHANGE) {
        return '連絡先交換リクエスト'
      }
      return ''
    },

    textCommentHtml () {
      if (this.directMessageReceiver.direct_message.content) {
        return this.directMessageReceiver.direct_message.content.replace(/\n/gi, '<br />')
      }
      return ''
    },

    ownMessage () {
      if (this.signinMember && this.directMessageReceiver.receiver_author_id === this.signinMember.author_id) {
        return true
      } else if (this.loggedInUser && this.directMessageReceiver.receiver_author_id === this.loggedInUser.author_id) {
        return true
      } else if (
        this.signinMember && 
        this.signinMember.organization_id === this.directMessageReceiver.organization_id &&
        this.signinMember.member_manager) {
        return true
      }
      return false
    },
  },

  methods: {
    initData () {
      if (this.directMessageReceiver) {
        this.responseFormDisplay = false
        this.responseText = ''
      }
    },

    formedTime (timestr) {
      if (timestr) {
        const targetTime = new Date(timestr)
        return moment(targetTime).format('MM月DD日 HH:mm')
      } else {
        return '未読'
      }
    },

    responseMessage () {
      console.log('responseMessage')
      if (!this.responseFormDisplay) {
        if (this.directMessageReceiver.direct_message.need_response_text) {
          this.responseFormDisplay = true
        } else {
          console.log('no need text send emit')
          this.$emit('responseMessageEvent', this.directMessageReceiver, '')
        }
      }
    },

    responseMessageWithText () {
      console.log('responseMessageWithText')
      if (this.responseText.length > 0) {
        this.$emit('responseMessageEvent', this.directMessageReceiver, this.responseText)
      }
    },

    reserveResponse () {
      this.$emit('reserveResponseEvent', this.directMessageReceiver)
    },

    closeModal () {
      this.$emit('closeEvent')
    },

    acceptRequest () {
      this.$emit('acceptExchangeEvent', this.directMessageReceiver)
    },

    rejectRequest () {
      this.$emit('rejectExchangeEvent', this.directMessageReceiver)
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';

.horus-direct-message-receiver-modal {
  .modal-header {
    display: flex;
    .title {
      width: 100%;
      text-align: center;
      img {
        width: 20px;
      }
    }
    .close-btn {
      width: 30px;
    }
  }
  .modal-body {
    padding: 10px;
    .direct-message-block {
      display: flex;
      .title {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100px;
        text-align: right;
        font-weight: bold;
      }
      .direct-message-content {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
          width: 30px;
        }
      }
    }
    .sent-at {
      .direct-message-content {
        font-size: 1.1rem;
        font-weight: bold;
        img {
          margin-left: 5px;
          width: 15px;
        }
      }
    }
    .sender {
      .direct-message-content {
        font-size: 1.1rem;
      }
    }
    .receiver {
      .direct-message-content {
        font-size: 1.1rem;
        .deputized-mark {
          display: inline-block;
          margin-left: 10px;
          padding: 3px;
          font-size: 0.8rem;
          background-color: $danger-color;
          color: #fff;
          border-radius: 3px;
        }
      }
    }
    .subject {
      .direct-message-content {
        font-size: 1.1rem;
      }
    }
    .read-at {
      .direct-message-content {
        .read-at-display {
          display: inline-block;
          margin-right: 10px;
        }
        img.responder-portrate {
          width: 20px;
        }
      }
    }
    .controll {
      text-align: right;
      button {
        cursor: pointer;
        margin-left: 10px;
        padding: 2px 5px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
      .response-form {
        margin-top: 10px;
      }
    }
  }
}
</style>