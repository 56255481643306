<template>
  <div class="horus-mail-error-log-detail-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />一斉同報メール送信ログ</h1>
      </div>
      <div class="modal-body">
        <div class="labled-content" v-if="systemMailLog">
          <div class="date-inputs">
            <div class="label">日時</div>
            <div class="content">{{ createdAtStr }}</div>
          </div>
          <div class="date-inputs">
            <div class="label">メールアドレス</div>
            <div class="content">{{ sendgridLog.email }}</div>
          </div>
          <div class="date-inputs">
            <div class="label">イベント</div>
            <div class="content">{{ sendgridLog.event }}</div>
          </div>
          <div class="content-inputs">
            <div class="label">本文</div>
            <div class="content" v-html="logJSON"></div>
          </div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="close-btn" @click="close">閉じる</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusMailErrorLogDetailModal',

  components: {
    HorusModalFrame,
  },

  props: {
    modalDisplay: Boolean,
    sendgridLog: Object,
  },

  emits: [
    'closeEvent',
  ],

  data () {
    return {
    }
  },

  computed: {
    createdAtStr () {
      var date = new Date(this.sendgridLog.created_at)
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm:ss')
      }
      return ''
    },

    logJSON () {
      if (this.sendgridLog && this.sendgridLog.json_string) {
        return this.sendgridLog.json_string.replace(/\n/gi, '<br />')
      }
      return ''
    },
    
  },

  methods: {
    closeModal () {
      this.$emit('closeEvent')
    },
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/_basicInputStyle.scss');

.horus-mail-error-log-detail-modal {
  .modal-body {
    padding: 10px 20px;
    .labled-content {
      .date-inputs {
        padding: 10px;
        display: flex;
        align-items: flex-start;
        .label {
          width: 100px;
          margin-right: 10px;
          text-align: right;
          font-weight: bold;
        }
        .content {
          margin-right: 10px;
          padding: 3px;
          text-align: left;
        }
      }
    }
    .submit-controlls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.close-btn {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>
