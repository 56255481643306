<template>
  <div class="horus-direct-message-detail-modal">
    <horus-modal-frame :showModalFlag="modalDisplay">
      <div class="modal-header">
        <div class="title">
          <img src="/static/images/direct_message_icon.svg" />
          送信ダイレクトメッセージ
        </div>
        <div class="close-btn">
          <img src="/static/images/x_icon.png" @click="closeModal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="sent-at direct-message-block">
          <div class="title">送信日時</div>
          <div class="direct-message-content">
            <span class="time-display">{{ formedTime(directMessage.sent_at) }}</span>
          </div>
        </div>
        <div class="type direct-message-block">
          <div class="title">回答</div>
          <div class="direct-message-content">{{ needResponseStr(directMessage) }}</div>
        </div>
        <div class="subject direct-message-block">
          <div class="title">表題</div>
          <div class="direct-message-content">{{ directMessage.subject }}</div>
        </div>
        <div class="text-content direct-message-block">
          <div class="title">本文</div>
          <div class="direct-message-content" v-html="textCommentHtml"></div>
        </div>
        <div class="title">送信先</div>
        <table class="receivers">
          <tr 
            class="direct-message-receiver" 
            v-for="directMessageReceiver in directMessage.direct_message_receivers"
            :key="'dmr' + directMessageReceiver.id"
            >
            <td class="receiver">
              <div class="receiver-content">
                <img :src="directMessageReceiver.receiverAuthor.portrateSrc" class="author-portrate" v-if="directMessageReceiver.receiverAuthor" />
                <span class="name" v-if="directMessageReceiver.receiverAuthor">{{ directMessageReceiver.receiverAuthor.name }}</span>
              </div>
            </td>
            <td class="read-at" :class="{notice: !directMessageReceiver.read_at}">
              <div class="time-display">既読日時 : {{ formedTime(directMessageReceiver.read_at) }}</div>
              <div class="reponse-display" v-if="directMessage.need_response_text">
                回答 : {{ directMessageReceiver.response_text }}
              </div>
            </td>
            <td class="response-author" v-if="directMessage.responseAuthor">
              <div class="response-author-content">
                <img :src="directMessageReceiver.responseAuthor.portrateSrc" class="author-portrate" v-if="directMessageReceiver.responseAuthor" />
                <span class="name" v-if="directMessageReceiver.responseAuthor">{{ directMessageReceiver.responseAuthor.name }}</span>
              </div>
            </td>
            <td clsss="response-author" v-if="!directMessageReceiver.responseAuthor"> --- </td>
          </tr>
        </table>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusDirectMessageDetailModal',

  components: {
    HorusModalFrame,
  },

  props: {
    modalDisplay: Boolean,
    directMessage: Object,
  },

  emits: [
    'closeEvent',
  ],

  computed: {
    textCommentHtml () {
      if (this.directMessage.content) {
        return this.directMessage.content.replace(/\n/gi, '<br />')
      }
      return ''
    },
  },

  methods: {
    formedTime (timestr) {
      if (timestr) {
        const targetTime = new Date(timestr)
        return moment(targetTime).format('MM月DD日 HH:mm')
      } else {
        return '未読'
      }
    },

    needResponseStr(directMessage) {
      if (directMessage.need_response_text) {
        return '必要'
      } else {
        return '不要'
      }
    },

    closeModal () {
      this.$emit('closeEvent')
    },
  },

}
</script>

<style lang="scss" scoped>
.horus-direct-message-detail-modal {
  .modal-header {
    display: flex;
    .title {
      width: 100%;
      text-align: center;
      img {
        width: 20px;
      }
    }
    .close-btn {
      width: 30px;
    }
  }
  .modal-body {
    padding: 10px 20px;
    .direct-message-block {
      display: flex;
      .title {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100px;
        text-align: right;
        font-weight: bold;
      }
      .direct-message-content {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
          width: 30px;
        }
      }
    }
    .sent-at {
      .direct-message-content {
        font-size: 1.1rem;
        font-weight: bold;
        img {
          margin-left: 5px;
          width: 15px;
        }
      }
    }
    .subject {
      .direct-message-content {
        font-size: 1.1rem;
      }
    }
    .title {
      font-weight: bold;
    }
    table.receivers {
      width: 100%;
      border-collapse: collapse;
      tr {
        td {
          padding: 5px;
          border-bottom: 1px solid #ddd;
          div {
            display: flex;
            align-items: center;
            img {
              width: 30px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>