<template>
  <div class="horus-mail-error-log-view">
    <h1 class="centering-title">メール送信エラーログ</h1>
    <h2>ログ一覧</h2>
    <div class="sent-list">
      <div class="list-column"
        v-for="sendgridLog in sendgridLogs"
        :key="'sglog' + sendgridLog.id"
        >
        <div class="list-line">
          <div class="sent-at">日時 : [{{ formedDate(sendgridLog.created_at) }}]</div>
          <div class="subject">メールアドレス：{{ sendgridLog.email }}</div>
          <div class="count">イベント : {{ sendgridLog.event }}</div>
          <div class="controll"><button type="button" class="show-btn" @click="showDetail(mailLog)">詳細</button></div>
        </div>
      </div>
    </div>
    <div class="contact-controlls"><button class="load-btn" @click="appendLog">更に読み込む</button></div>
    <horus-mail-error-log-detail-modal 
      :modalDisplay="detailModalDisplay"
      :sendgridLog="detailTargetLog"
      v-on:closeEvent="closeDetail"/>
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { SendgridLog } from '@/api'

import HorusMailErrorLogDetailModal from '@/components/manageSystem/mailErrorLog/HorusMailErrorLogDetailModal.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusMailErrorLogView',

  components: {
    HorusMailErrorLogDetailModal,
    HorusConfirmDialog,
  },

  data () {
    return {
      sendgridLogs: [],
      limit: 100,
      currentOffset: 0,
      detailTargetLog: null,
      detailModalDisplay: false
    }
  },

  mounted () {
    this.initDatas()
  },

  methods: {
    initDatas () {
      this.currentOffset = 0
      this.sendgridLogs = []
      this.detailTargetLog = null
      this.loadLog()
    },

    loadLog () {
      SendgridLog.fetch({ offset: this.currentOffset }).then(res => {
        console.log(res)
        this.sendgridLogs = this.sendgridLogs.concat(res.send_grid_logs)
        this.currentOffset += this.sendgridLogs.length
      }).catch(err => {
        console.error(err.response)
      })
    },

    appendLog () {
      this.loadLog()
    },

    reloadLog () {
      this.initDatas()
    },

    formedDate (time) {
      var date = new Date(time)
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm:ss')
      }
    },

    showDetail (sendgridLog) {
      this.detailTargetLog = sendgridLog
      this.detailModalDisplay = true
    },

    closeDetail () {
      console.log('close detail')
      this.detailModalDisplay = false
      this.detailTargetLog = null
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-mail-error-log-view {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .contact-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            width: 200px;
            font-weight: bold;
          }
          &.mail {
            width: 250px;
          }
          &.from {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .contact-controlls {
    margin: 10px 0;
    text-align: center;
    .load-btn {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
