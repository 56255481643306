<template>
  <div class="horus-address-entry-select-modal">
    <horus-modal-frame :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>ダイレクトメッセージ送信先選択</h1>
        <div class="selection-controll">
          <div class="left-buttons">
            <button class="horus-author-selection-all" type="button" @click="selectAllDisplay">表示中をすべて選択</button>
            <button class="horus-author-selection-clear" type="button" @click="clearSelect">クリア</button>
          </div>
          <div class="right-buttons">
            <button class="horus-author-selection-commit" type="button" @click="commitSelected">OK</button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="display-controll">
          <button class="show-only-favorite-btn" @click="toggleShowOnlyFavorite" v-show="!displayFavoriteOnly">お気に入りのみ表示</button>
          <button class="show-only-favorite-btn" @click="toggleShowOnlyFavorite" v-show="displayFavoriteOnly">通常表示</button>
        </div>
        <div class="favorite-column" v-show="displayFavoriteOnly">
          <div class="entry-column"
            v-for="entry in favoriteEntries"
            :key="'favoent' + entry.id"
            @click="toggleSelection(entry)">
            <horus-custom-check-box :type="'small'"
              :active="isSelected(entry)"
              :enabled="true" />
            <img :src="entry.author.portrateSrc" v-if="entry.author" />
            <span class="entry-name">{{ entry.name }}</span>
          </div>
        </div><!-- favorite list -->
        <div class="address-entries" v-show="!displayFavoriteOnly">
          <div class="family-entries" v-if="familyEntries.length > 0">
            <div class="selection-group-head" @click="toggleAllFamily">
              <horus-custom-check-box :type="'small'"
                :active="allFamilySelected"
                :enabled="true" />
              <img src="/static/images/family.png" />
              <span class="selection-group-name">家族</span>
            </div>
            <div class="selection-group">
              <div class="selection-entry-block"
                v-for="entry in familyEntries"
                :key="'familyent' + entry.id"
                @click="toggleSelection(entry)">
                <horus-custom-check-box :type="'small'"
                  :active="isSelected(entry)"
                  :enabled="true" />
                <img :src="entry.author.portrateSrc" v-if="entry.author" />
                <span class="author-name">{{ entry.name }}</span>
              </div>
            </div>
          </div><!-- family etnries -->
          <div class="other-entries" v-if="otherEntries.length > 0">
            <div class="selection-group-head" @click="toggleAllOthers">
              <horus-custom-check-box :type="'small'"
                :active="allOtherSelected"
                :enabled="true" />
              <span class="selection-group-name">その他</span>
            </div>
            <div class="selection-group">
              <div class="selection-entry-block"
                v-for="entry in otherEntries"
                :key="'familyent' + entry.id"
                @click="toggleSelection(entry)">
                <horus-custom-check-box :type="'small'"
                  :active="isSelected(entry)"
                  :enabled="true" />
                <img :src="entry.author.portrateSrc" v-if="entry.author" />
                <span class="author-name">{{ entry.name }}</span>
              </div>
            </div>
          </div><!-- other etnries -->
          <div class="organizatio-entries"
            v-for="organizationEntry in organizationEntries"
            :key="'orgent' + organizationEntry.id">
            <div class="selection-group-head" @click="toggleAllMember(organizationEntry)">
              <horus-custom-check-box :type="'small'"
                :active="allMemberSelected(organizationEntry)"
                :enabled="true" />
              <img src="/static/images/org_member.png" />
              <span class="selection-group-name">{{ organizationEntry.name }}</span>
            </div>
            <div class="selection-group">
              <div class="selection-entry-block" @click="toggleSelection(organizationEntryToObject(organizationEntry))" v-if="organizationEntry.exist">
                <horus-custom-check-box :type="'small'"
                  :active="isSelected(organizationEntry)"
                  :enabled="true" />
                <img src="/static/images/org_member.png" class="organization-icon" />
                <span class="author-name">{{ organizationEntry.name }} (代表)</span>
              </div>
              <div class="selection-entry-block"
                v-for="entry in organizationEntry.entries"
                :key="'suoment' + entry.id"
                @click="toggleSelection(entry)">
                <horus-custom-check-box :type="'small'"
                  :active="isSelected(entry)"
                  :enabled="true" />
                <img :src="entry.author.portrateSrc" v-if="entry.author" />
                <span class="author-name">{{ entry.name }}</span>
              </div><!-- suom list -->
            </div>
          </div><!-- organization list -->
        </div><!-- address entries -->
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusAddressEntry from '@/mixin/HorusAddressEntry';

import * as ADDRESS_ENTRY_TYPE from '@/assets/constants/addressEntryType.js'

export default {
  mixins: [HorusAddressEntry],

  name: 'HorusAddressEntrySelectModal',

  components: {
    HorusCustomCheckBox,
    HorusModalFrame,
  },

  props: {
    modalDisplay: Boolean,
    selectTargets: Array,
  },

  emits: [
    'selectFinished',
  ],

  data () {
    return {
      displayFavoriteOnly: false,
      selected: [],
    }
  },

  mounted () {
    this.loadAddressEntries()
  },

  watch: {
    modalDisplay: { handler: 'initSelected' },
    addressEntries: { handler: 'classifyAddressEntries'},
  },


  computed: {
    allFamilySelected () {
      return this.familyEntries.every((element) => this.selected.some((elm) => elm.target_user_id === element.target_user_id))
    },

    allOtherSelected () {
      return this.otherEntries.every((element) => this.selected.some((elm) => elm.target_user_id === element.target_user_id))
    },
  },

  methods: {
    initSelected () {
      if (this.modalDisplay) {
        this.selected = this.selectTargets
      }
    },

    selectAllDisplay () {
      if (this.displayFavoriteOnly) {
        this.selected = [...this.favoriteEntries]
      } else {
        this.selected = [...this.familyEntries]
        this.selected = this.selected.concat(this.otherEntries)
        for(let organizationEntry of this.organizationEntries) {
          if (organizationEntry.exist) {
            this.selected.push(this.organizationEntryToObject(organizationEntry))
          }
          this.selected = this.selected.concat(organizationEntry.entries)
        }
      }
    },

    clearSelect () {
      this.selected = []
    },

    commitSelected () {
      this.$emit('selectFinished', this.selected)
    },

    toggleShowOnlyFavorite () {
      this.displayFavoriteOnly = !this.displayFavoriteOnly
    },

    organizationEntryToObject (organizationEntry) {
      return {
        target_organization_id: organizationEntry.target_organization_id, 
        name: organizationEntry.name,
        target_type: ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION,
        favorite: organizationEntry.favorite
      }
    },

    toggleSelection (entry) {
      console.log(entry)
      if (entry.target_user_id) {
        console.log('entry user')
        let index = this.selected.findIndex((elm) => elm.target_user_id === entry.target_user_id)
        console.log('selected index:' + index)
        if (index >= 0) {
          this.selected.splice(index, 1)
        } else {
          this.selected.push(entry)
        }
      } else if (entry.target_suom_id) {
        console.log('entry suom')
        let index = this.selected.findIndex((elm) => elm.target_suom_id === entry.target_suom_id)
        console.log('selected index:' + index)
        if (index >= 0) {
          this.selected.splice(index, 1)
        } else {
          this.selected.push(entry)
        }
      } else if (entry.target_type == ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
        console.log('entry org')
        let index = this.selected.findIndex((elm) => elm.target_organization_id === entry.target_organization_id)
        console.log('selected index:' + index)
        if (index >= 0) {
          this.selected.splice(index, 1)
        } else {
          this.selected.push(entry)
        }
      }
    },

    toggleAllFamily () {
      if (this.allFamilySelected) {
        for(let ent of this.familyEntries) {
          let selectIndex = this.selected.findIndex((elm) => elm.target_user_id === ent.target_user_id)
          if (selectIndex >= 0) {
            this.selected.splice(selectIndex, 1)
          }
        }
      } else {
        for(let ent of this.familyEntries) {
          if (!this.selected.some((elm) => elm.target_user_id === ent.target_user_id)) {
            this.selected.push(ent)
          }
        }
      }
    },

    toggleAllOthers () {
      if (this.allOtherSelected) {
        for(let ent of this.otherEntries) {
          let selectIndex = this.selected.findIndex((elm) => elm.target_user_id === ent.target_user_id)
          if (selectIndex >= 0) {
            this.selected.splice(selectIndex, 1)
          }
        }
      } else {
        for(let ent of this.otherEntries) {
          if (!this.selected.some((elm) => elm.target_user_id === ent.target_user_id)) {
            this.selected.push(ent)
          }
        }
      }
    },

    allMemberSelected (organizationEntry) {
      if (organizationEntry.entries.every(
          (entry) => this.selected.some((elm) => elm.target_suom_id === entry.target_suom_id))
        ) {
        if (organizationEntry.exist) {
          if (this.selected.some((elm) => elm.target_organization_id === organizationEntry.target_organization_id && elm.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION)) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return false
      }
    }, 

    toggleAllMember (organizationEntry) {
      if (this.allMemberSelected(organizationEntry)) {
        if (organizationEntry.exist) {
          let orgIndex = this.selected.findIndex((ent) => ent.target_organization_id === organizationEntry.target_organization_id && ent.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION)
          if (orgIndex >= 0) {
            this.selected.splice(orgIndex, 1)
          }
        }
        for(let entry of organizationEntry.entries) {
          let suomIndex = this.selected.findIndex((ent) => ent.target_suom_id == entry.target_suom_id)
          if (suomIndex >= 0) {
            this.selected.splice(suomIndex, 1)
          }
        }
      } else {
        if (this.organizationEntries.exist) {
          if (!this.selected.some((ent) => ent.target_organization_id === organizationEntry.target_organization_id && ent.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION)) {
            this.selected.push(this.organizationEntryToObject(organizationEntry))
          }
        }
        for(let entry of organizationEntry.entries) {
          if (!this.selected.some((ent) => ent.target_suom_id === entry.target_suom_id)) {
            this.selected.push(entry)
          }
        }
      }
    },

    isSelected (entry) {
      if (entry.target_user_id) {
        return this.selected.some((ent) => ent.target_user_id === entry.target_user_id)
      } else if (entry.target_suom_id) {
        return this.selected.some((ent) => ent.target_suom_id === entry.target_suom_id)
      } else if (entry.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
        return this.selected.some((ent) => ent.target_organization_id === entry.target_organization_id && ent.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION)
      }
      return false
    },
  },

}
</script>

<style lang="scss" scoped>
.horus-address-entry-select-modal {
  .modal-header {
    .selection-controll {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      button {
        cursor: pointer;
        padding: 2px 5px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
      }
      .left-buttons {
        button {
          margin-right: 10px;
        }
      }
    }
  }
  .modal-body {
    padding: 10px;
    .display-controll {
      button {
        margin-bottom: 10px;
        margin-right: 10px;
        cursor: pointer;
        padding: 2px 5px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
      }
    }
    .favorite-column {
      display: flex;
      flex-wrap: wrap;
      .entry-column {
        display: flex;
        align-items: center;
        margin: 10px;
        img {
          margin-right: 5px;
          width: 30px;
        }
      }
    }
    .address-entries {
      .selection-group-head {
        display: flex;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #aaa;
      }
      .selection-group {
        display: flex;
        flex-wrap: wrap;
        .selection-entry-block {
          display: flex;
          align-items: center;
          margin: 10px;
          img {
            margin-right: 5px;
            width: 30px;
            &.organization-icon {
              width: 20px;
            }
          }
        }
      }
    }
  }
}
</style>