<template>
  <div class="horus-address-entry-restorable-modal">
    <horus-modal-frame :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>連絡先リストの復元</h1>
      </div>
      <div class="modal-body">
        <div
          class="restorable-target"
          v-for="(restorable, index) in restorableList"
          :key="'rest' + index">
          <div class="target-info">
            <div class="name">{{ restorable.target_name }}</div>
            <div class="type">({{ targetTypeName(restorable) }})</div>
          </div>
          <div class="controll">
            <button class="restore-btn" @click="restore(restorable)">復元</button>
          </div>
        </div>
        <div class="controll">
          <button class="cancel-btn" @click="closeModal">キャンセル</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

import * as ADDRESS_ENTRY_TYPE from '@/assets/constants/addressEntryType.js'

export default {
  name: 'HorusAddressEntryRestorableModal',

  components: {
    HorusModalFrame,
  },

  props: {
    modalDisplay: Boolean,
    restorableList: Array,
    loggedInUser: Object,
    signinMember: Object,
  },

  emits: [
    'restorableEntryEvent',
    'closeModalEvent',
  ],

  methods: {
    targetTypeName (restorable) {
      if (restorable.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_FAIMILY) {
        return '家族'
      } else if (restorable.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_NONE) {
        if (this.signinMember) {
          return 'かかりつけ利用者その他'
        } else {
          return 'その他の利用者'
        }
      } else if (restorable.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
        return '施設代表'
      } else if (restorable.target_type === ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_SUOM) {
        return '施設職員'
      }
    },

    restore (restorable) {
      this.$emit('restorableEntryEvent', restorable)
    },

    closeModal () {
      this.$emit('closeModalEvent')
    },
  }

}
</script>

<style lang="scss" scoped>
.horus-address-entry-restorable-modal {
  .modal-body {
    padding: 10px 20px;
    .restorable-target {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid #ddd;
      .target-info {
        display: flex;
        align-items: center;
        .name {
          font-weight: bold;
        }
        .type {
          margin-left: 10px;
        }
      }
      .controll {
        button {
          cursor: pointer;
          padding: 2px 5px;
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
    .controll {
      padding: 10px;
      text-align: right;
      button {
        cursor: pointer;
        padding: 2px 5px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #fff;
        color: #666;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
}
</style>