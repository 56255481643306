import { AddressEntry } from '@/api'
import { Author, Organization } from '@/models'

import * as ADDRESS_ENTRY_TYPE from '@/assets/constants/addressEntryType.js'

export default {

  data () {
    return {
      addressEntries: [],
      favoriteEntries: [],
      familyEntries: [],
      otherEntries: [],
      organizationEntries: [],
    }
  },

  methods: {
    async loadAddressEntries () {
      AddressEntry.fetchList().then(async res => {
        console.log('load address entry......')
        console.log(res)
        this.addressEntries = res.address_entries
        this.familyEntries = []
        this.otherEntries = []
        this.organizationEntries = []
        let unloadAuthors = []
        let unloadOrganizations = []
        for(let entry of this.addressEntries) {
          if (entry.author_id && !Author.find(entry.author_id)) {
            unloadAuthors.push(entry.author_id)
          }
          if (entry.target_organization_id && !Organization.find(entry.target_organization_id)) {
            unloadOrganizations.push(entry.target_organization_id)
          }
        }
        if (unloadAuthors.length > 0) {
          await Author.api().fetchByID({ idList: unloadAuthors })
        }
        if (unloadOrganizations.length > 0) {
          await Organization.api().fetchByID({ idList: unloadOrganizations })
        }
      }).catch(error => { console.error(error) })
    },

    classifyAddressEntries () {
      for(let entry of this.addressEntries) {
        if (entry.author_id) {
          entry.author = Author.find(entry.author_id)
        }
        if (entry.favorite) {
          this.favoriteEntries.push(entry)
        }
        if (entry.target_type == ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_FAIMILY) {
          this.familyEntries.push(entry)
        } else if (entry.target_type == ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION) {
          let orgEntry = this.organizationEntries.find(org => org.id == entry.target_organization_id)
          let org = Organization.find(entry.target_organization_id)
          if (orgEntry) {
            if (!orgEntry.exist) {
              orgEntry.exist = true
              orgEntry.name = entry.name
              orgEntry.favorite = entry.favorite
              orgEntry.target_organization_id = entry.target_organization_id
            }
          } else {
            orgEntry = { 
              id: entry.id, 
              target_organization_id: entry.target_organization_id,
              target_type: ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION,
              entries: [], 
              favorite: entry.favorite, 
              name: entry.name, 
              organization: org, 
              exist: true 
            }
          }
          this.organizationEntries.push(orgEntry)
        } else if (entry.target_type == ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_SUOM) {
          let orgEntry = this.organizationEntries.find(org => org.id == entry.target_organization_id)
          if (!orgEntry) {
            let org = Organization.find(entry.target_organization_id)
            orgEntry = {
              id: entry.target_organization_id, 
              target_organization_id: entry.target_organization_id,
              target_type: ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_ORGANIZATION,
              entries: [], 
              favorite: false, 
              name: org.shortName, 
              organization: org,
              exist: false, 
            }
            this.organizationEntries.push(orgEntry)
          }
          orgEntry.entries.push(entry)
        } else if (entry.target_type == ADDRESS_ENTRY_TYPE.ADDRESS_TARGET_TYPE_NONE) {
          this.otherEntries.push(entry)
        }
      }
      // sort
      this.favoriteEntries.sort((f,s) => f.name.localeCompare(s.name))
      this.familyEntries.sort((f,s) => f.name.localeCompare(s.name))
      this.otherEntries.sort((f,s) => f.name.localeCompare(s.name))
      this.organizationEntries.sort((f,s) => f.name.localeCompare(s.name))
      for(let org of this.organizationEntries) {
        org.entries.sort((f,s) => f.name.localeCompare(s.name))
      }
    },
  }

}